import React from "react";
import Image from "../../components/Image";
import LegalNote from "../../components/LegalNote";
import Links from "../../components/Links";
import TextLinks from "../../components/TextLinks";
import { environment } from "./../../environment";

const svg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
                <g>
                  <path fill="#2DA343" d="M7.415,8.44H2.064c-0.553,0-1-0.448-1-1V3.675c0-2.027,1.649-3.676,3.676-3.676   c2.026,0,3.676,1.649,3.676,3.676v3.764C8.416,7.992,7.968,8.44,7.415,8.44z M3.065,6.439h3.349V3.675C6.414,2.751,5.663,2,4.74,2   C3.816,2,3.065,2.751,3.065,3.675V6.439z"/>
                  <path fill="#2DA343" d="M21.935,24.001H7.415c-0.553,0-1.001-0.448-1.001-1.001V1c0-0.553,0.448-1,1.001-1h14.521   c0.553,0,1.001,0.448,1.001,1v22C22.936,23.553,22.488,24.001,21.935,24.001z M8.416,21.999h12.519V2H8.416V21.999z"/>
                  <rect x="4.74" y="-0.001" fill="#2DA343" width="2.675" height="2.001"/>
                  <path fill="#2DA343" d="M17.269,7.446h-5.188c-0.553,0-1.001-0.448-1.001-1s0.448-1,1.001-1h5.188c0.553,0,1.001,0.448,1.001,1   S17.822,7.446,17.269,7.446z"/>
                  <path fill="#2DA343" d="M17.269,12.727h-5.188c-0.553,0-1.001-0.448-1.001-1.001s0.448-1.001,1.001-1.001h5.188   c0.553,0,1.001,0.448,1.001,1.001S17.822,12.727,17.269,12.727z"/>
                  <path fill="#2DA343" d="M17.269,18.008h-5.188c-0.553,0-1.001-0.448-1.001-1.001s0.448-1.001,1.001-1.001h5.188   c0.553,0,1.001,0.448,1.001,1.001S17.822,18.008,17.269,18.008z"/>
                </g>
              </svg>`;

const Infobox = (params) => {
  if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
    console.log("Infobox");
    console.log(params);
  }

  let colorBack = "";
  if (params.block["en"]["Background"] !== "" && params.block["en"].hasOwnProperty("Background")) {
    colorBack = "b-" + params.block["en"]["Background"];
  }
  let innerSection = "infobox";
  let innerContainer = "container d-flex f-column jc-center";
  if (params.block["en"]["InnerBackground"] !== "" && params.block["en"].hasOwnProperty("InnerBackground")) {
    if (params.block["en"]["InnerBackground"] === true) {
      innerContainer = innerContainer + " " + colorBack;
    } else {
      innerSection = innerSection + " " + colorBack;
    }
  }

  function Tags({item}) {
    const splitTags = item?.Tags ? item.Tags.split(',') : [];
    let checkSame = []
    return (
      splitTags.map((tag) => {
          let name = tag,
              tagLast = tag.split('/'),
              nameTag = tagLast[tagLast.length - 2];
          if (name === '' || name === undefined) name = nameTag;
          if (!checkSame.includes(name)) {
              checkSame.push(name)
              return <span className="c-color1 custom-h-sm mb-10">{nameTag}</span>
          }
          return null
      })
    )
  }

  function InfoboxItem({ item }) {
    let altText = "";
    if (item.AltText) {
      altText = item.AltText;
    } else if (!item.AltText && item.Title) {
      altText = item.Title;
    } else {
      altText = "Bank Rumia image";
    }
    let altTextIcon = "";
    if (item.AltTextIcon) {
      altTextIcon = item.AltTextIcon;
    } else if (!item.AltTextIcon && item.Title) {
      altTextIcon = item.Title;
    } else {
      altTextIcon = "Bank Rumia icon";
    }
    return (
      <div className={`${item.IsIframe ? 'infobox-item calc' : 'infobox-item'}`}>
        {item.IsIframe && item.IframeUrl && 
          // <iframe src={item.IframeUrl} title="ZBS Kalkulator"></iframe>
          <div style={{height: "100%"}} dangerouslySetInnerHTML={{__html: item.IframeUrl}}></div>
        }
        {!item.IsIframe &&
        <>
          <div className="infobox-item-img d-flex ai-center jc-center">
            {item["Image"] && item["Image"].hasOwnProperty("path") &&
              item["Image"]["path"] !== "" 
              ? (<Image src={item["Image"]} alt={altText} />) 
              : ("")
            }
          </div>
          <div className="container infobox-item-body">
            {item.Tags &&
              <Tags item={item} />
            }
            {!item.Tags && 
              <span className="c-color1 custom-h-sm mb-10"></span>
            }
            <div className="top-box d-flex ai-center jc-between">
              {item.Title && <h2>{item.Title}</h2>}
              <div className="infobox-item-img icons d-flex ai-center jc-center">
                {item["Icon"] && item["Icon"].hasOwnProperty("path") &&
                  item["Icon"]["path"] !== "" 
                  ? (<Image src={item["Icon"]} alt={altTextIcon} />) 
                  : ("")
                }
              </div>
            </div>
            {item.Text && (
              <div className="text">
                <TextLinks text={item.Text} line={params.line} />
              </div>
            )}
            <div className="buttons d-flex">
              {item.ButtonName && item.ButtonLink && (
                <Links
                  target={`${item.InNewOpen}`}
                  url={item.ButtonLink}
                  name={item.ButtonName}
                  class="button primary"
                  line={params.line}
                />
              )}
              {item.LegalNote && <LegalNote classButton="button icon-btn disclaimer" LegalNote={item.LegalNote} svg={svg} />}
            </div>
          </div>
        </>
        }
      </div>
    );
  }

  return (
    <section className={innerSection}>
      <div className="wrapper">
        <div className={innerContainer}>
          {params.block["en"]["Title"] && (
            <h3 className="mb-20">{params.block["en"]["Title"]}</h3>
          )}
          <div className="infobox-item-wrapper">
            {params.block["en"].Item.map((item, index) => {
              return <InfoboxItem item={item} key={index} />;
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Infobox;