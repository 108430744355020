export const project = {
    name: 'zbs',
    fullname: 'Bank Rumia Spółdzielczy',
    host: window.location.host,
    online: window.location.host.search('cms') >= 0 ? false : true
};
export const environment = {
    cmsName: 'cms-' + project.name,
    localhost: 'localhost',
    port: '3003',
    cookieName: 'zbs',
    devName: 'test-' + project.name,
    testName: 'test-' + project.name,
    prodName: 'https://bankrumia.pl',
    devCmsName: 'test-cms-' + project.name,
    testCmsName: 'test-cms-' + project.name,
    prodCmsName: 'cms-' + project.name,
    siteSites: '/sites/' + project.name,
    siteUrlDev: 'https://test-' + project.name + '.qrqs.eu',
    siteUrlTest: 'https://test-' + project.name + '.qrqs.eu',
    siteUrlProd: 'https://bankrumia.pl',
    siteCMSDev: 'https://test-cms-' + project.name + '.qrqs.eu',
    siteCMSTest: 'https://test-cms-' + project.name + '.qrqs.eu',
    siteCMSProd: 'https://cms-' + project.name + '.qrqs.eu',
    cmsPageUrl: '/json/sites/' + project.name,
    page: 'index.html',
    pageJson: 'index.html.json',
    mainPage: '/dla-ciebie/',
    mainPageJson: '/dla-ciebie/index.html.json',
    error404Page: '/.errors/404.html',
    error404Json: '/.errors/404.html.json',
    navJson: '/.includes/nav/nav-json.json',
    categoriesJson: '/.includes/categories/categories.json',
    galleryJson: '/.includes/galleries/list.json',
    searchPath: 'https://search.interconsystems.pl/s/AB43D6/default/',
    searchNewsApi: '/api/jsonws/news/search/',
    searchPage: '/wyniki-wyszukiwania/',
    newsMainLink: '/aktualnosci/',
    searchProm: '/.includes/search/search-main.csv',
    devreCaptchaKey: '6LcDzpYjAAAAAC0sT-rlkDtrJIxx0-N6pdV0yacV',
    testreCaptchaKey: '6LdDjBQgAAAAAGcbrt4z1XImLP07t6JgxPGmNcVd',
    prodreCaptchaKey: '6Lch2E0gAAAAANGDpyTeLLij8iYzrr3AFvZFIvKe',
    defaultImage: '/.templates/img/logo/herb.png',
    defaultLogo: '/.templates/img/logo/logo-zbs.png',
    logoMain: '/.templates/img/logo/logo-zbs-big.png',
    branches: '/api/branches/branches.json',
    cashDispensers: '/api/cashDispensers/cashDispensers.json',
    currencies: '/api/currencies/currencies.json',
    testMapApiKey: 'AIzaSyAmOa-3O-pwynmMX8SGl6Yd0SgaLFsGFa8',
    prodMapApiKey: 'AIzaSyD2AboUgJpGZdmUhJupcHLElgiuGWT7I3Y',
    formApiContact: '/forms-api/contact/getConfig'
};
