import { environment } from './environment';

const log = (message, params) => {

    const orig = window.origin;
    let action = 'log', force;

    if (params) {
        action = params.includes('w') ? 'warn' : params.includes('e') ? 'error' : 'log';
        force = params.includes('f');
    }

    if (force || orig.includes(environment.localhost) || orig.includes(environment.devName)) {
        console[action](message);
    }
};

export default log;