import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { environment } from "./environment";
import Loaders from "./Loaders.js";
import Svg from "./components/Svg";

const Redirect = {
  "news/": 'aktualnosci/'
};
const OuterRedirect = {
  "outer/": 'https://outer.com/',
};

function NavSide(params) {
  if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
    console.log('Build Side Nav')
  }
  let [navData, setNavData] = useState("");
  useParams();
  let [jsonStatus, setJsonStatus] = useState(null);
  let [sideNav, setSideNav] = useState([]);
  let [sideThirdNav, setSideThirdNav] = useState([]);
  useEffect(() => {
    let fetchNavs = async () => {
      let url = null;
      if (window.location.origin.includes(environment.cmsName)) {
        url = window.location.origin + environment.navJson;
      }
      else if (window.location.origin.includes(environment.localhost)) {
        url = environment.siteUrlDev + environment.navJson;
      }
      else {
        url = window.location.origin + environment.navJson;
      }
      let response = "";
      try {
        response = await Axios(url);
        setJsonStatus(true);
      } catch (err) {
        setJsonStatus(false);
        response = "error";
      }
      if (response.hasOwnProperty('data')) {
        setNavData(response.data);
      }
      else {
        setJsonStatus(false);
      }
    };
    fetchNavs();
  },[]);

  if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
    console.log('navData');
    console.log(navData);
  }

  const setNavPath = path => {
    let sideArray = []
    let index = 0
    if (navData) {
      navData.map((item, i) => {
        if (item.path === path && item.hasOwnProperty('navShow') && item.navShow !== 'false') {
          if (navData[i].hasOwnProperty('children')) {
            navData[i].children.map((subitem, ii) => {
              if (subitem.hasOwnProperty('navShow') && subitem.navShow !== 'false') {
                sideArray.push(subitem)
                index = ii
              }
              return null
            })
            sideArray[0].index = index
          }
        }
        return null
      })
    }
    setSideNav(sideArray)
  };

  const setNavThirdPath = path => {
    let sideArray = []
    let index = 0
    if (sideNav) {
      sideNav.map((item, i) => {
        if (item.path === path && item.hasOwnProperty('navShow') && item.navShow !== 'false') {
          sideArray.push(item)
          index = i
        }
        return null
      })
      sideArray[0].index = index
    }
    setSideThirdNav(sideArray)
  };

  function OpenSide(path) {
    const sidenav = document.getElementById("sidemenu");
    if (sidenav.classList.contains('show')) {
      sidenav.classList.remove("show");
    }
    else if (!sidenav.classList.contains('show')) {
      setTimeout(function(){ 
        sidenav.classList.add("show");
      }, 1);
    }
    setNavPath(path)
  };
  function OpenThirdSide(path) {
    const sidenav = document.getElementById("sidemenu");
    if (sidenav.classList.contains('show-third')) {
      sidenav.classList.remove("show-third");
    }
    else if (!sidenav.classList.contains('show-third')) {
      setTimeout(function(){ 
        sidenav.classList.add("show-third");
      }, 1);
    }
    setNavThirdPath(path)
  };
  function CloseMenu() {
    const sidemenu = document.getElementById("sidemenu");
    if (sidemenu.classList.contains('open')) {
      sidemenu.classList.remove("open");
    }
  };
  function CloseSide() {
    const sidenav = document.getElementById("sidemenu");
    if (sidenav.classList.contains('show')) {
      sidenav.classList.remove("show");
    }
    if (sidenav.classList.contains('show-third')) {
      sidenav.classList.remove("show-third");
    }
  };
  function CloseThirdSide() {
    const sidenav = document.getElementById("sidemenu");
    if (sidenav.classList.contains('show-third')) {
      sidenav.classList.remove("show-third");
    }
  };
  function RenderNavs() {
    return (
      <nav className="menu-transform-items b-white" id="sidenav">
        <ul className="menu-items">
          <RenderMainNav />
        </ul>
        <ul className="menu-items submenu-items">
          <li className="menu-item back-span" onClick={(e) => {e.preventDefault(); CloseSide();}}>
            <Svg name="BackArrow" class="menu-icon"/>
          </li>
          <RenderSideNav />
        </ul>
        <ul className="menu-items submenu-items subsubmenu-items">
          <li className="menu-item back-span" onClick={(e) => {e.preventDefault(); CloseThirdSide();}}>
            <Svg name="BackArrow" class="menu-icon"/>
          </li>
          <RenderSideThirdNav />
        </ul>
      </nav>
    )
  }
  function RenderMainNav() {
    return (
      navData && navData.map((item, i) => {
        if (item.hasOwnProperty('navShow') && item.navShow !== 'false') {
          if (item.children.length > 0) {
            return (
              <li id={`menu-item-`+i} key={`menu-item-`+i} className="menu-item current" onClick={(e) => {e.preventDefault();OpenSide(item.path);}}>
                <span className="menu-icon menu-icon-empty"></span>
                <span className="menu-text">
                  <p>{item.text}</p>
                </span>
                <div className="childs-icon">
                  <Svg name="MenuArrowLeft" />
                </div>
              </li>
            )
          } else {
            return (
              params.line === "offline" ?
              <li id={`menu-item-`+i} key={`menu-item-`+i} className="menu-item current" onClick={(e) => {e.preventDefault();CloseMenu();}}>
                <span className="menu-icon menu-icon-empty"></span>
                <span className="menu-text">
                  <RedirectLink link={item.path} text={item.text} line="offline"/>
                </span>
              </li>
              :
              <li id={`menu-item-`+i} key={`menu-item-`+i} className="menu-item current" onClick={(e) => {e.preventDefault();CloseMenu();}}>
                <span className="menu-icon menu-icon-empty"></span>
                <span className="menu-text">
                  <RedirectLink link={item.path} text={item.text} line="online"/>
                </span>
              </li>
            )
          }
        }
        return null
      })
    )
  }

  function RenderSideNav() {
    return (
      sideNav && sideNav.map((item, i) => {
          if (item.children.length > 0) {
            return (
              <li id={`menu-item-sec-`+i} key={`menu-item-sec-`+i} className="menu-item current" onClick={(e) => {e.preventDefault();OpenThirdSide(item.path);}}>
                <span className="menu-icon menu-icon-empty"></span>
                <span className="menu-text">
                  <p>{item.text}</p>
                </span>
                <div className="childs-icon">
                  <Svg name="MenuArrowLeft" />
                </div>
              </li>
            )
          } else {
            return (
              params.line === "offline" ?
              <li id={`menu-item-sec-`+i} key={`menu-item-sec-`+i} className="menu-item current" onClick={(e) => {e.preventDefault();CloseMenu();}}>
                <span className="menu-icon menu-icon-empty"></span>
                <span className="menu-text">
                  <RedirectLink link={item.path} text={item.text} line="offline"/>
                </span>
              </li>
              :
              <li id={`menu-item-sec-`+i} key={`menu-item-sec-`+i} className="menu-item current" onClick={(e) => {e.preventDefault();CloseMenu();}}>
                <span className="menu-icon menu-icon-empty"></span>
                <span className="menu-text">
                  <RedirectLink link={item.path} text={item.text} line="online"/>
                </span>
              </li>
            )
          }
      })
    )
  }

  function RedirectLink(item) {
    if (item.line === 'online') {
      if (Redirect.hasOwnProperty(item.link)) {
        return (
          <Link to={Redirect[item.link]} className="menu-text">
            <p>{item.text}</p>
          </Link>
        )
      }
      else {
        return (
          <Link to={item.link} className="menu-text">
            <p>{item.text}</p>
          </Link>
        )
      }
    }
    else {
      if (Redirect.hasOwnProperty(item.link)) {
        return (
          <a href={Redirect[item.link]} className="menu-text">
            <p>{item.text}</p>
          </a>
        )
      }
      else {
        return (
          <a href={item.link} className="menu-text">
            <p>{item.text}</p>
          </a>
        )
      }
    }
  }

  function RenderSideThirdNav() {
    return (
      params.line === "offline"
        ? sideThirdNav.map((item, i) => {
          return (
            <>
              {item.children && item.children.map((subitem, ii) => {
                if (OuterRedirect.hasOwnProperty(subitem.path)) {
                  return (
                    <li id={`menu-item-`+item.index+'_'+ii} key={`menu-item-`+item.index+'_'+ii} className="menu-item" onClick={(e) => {CloseMenu();}}>
                      <span className="menu-icon menu-icon-empty"></span>
                      <a href={OuterRedirect[subitem.path]} className="menu-text">
                        <p>{subitem.text}</p>
                      </a>
                    </li>
                  )
                }
                else {
                  return (
                    <li id={`menu-item-`+item.index+'_'+ii} key={`menu-item-`+item.index+'_'+ii} className="menu-item" onClick={(e) => {CloseMenu();}}>
                      <span className="menu-icon menu-icon-empty"></span>
                      <a href={subitem.path} className="menu-text">
                        <p>{subitem.text}</p>
                      </a>
                    </li>
                  )
                }
              })}
            </>
          )
        })
        : sideThirdNav.map((item, i) => {
          return (
            <span key={`menu-item-`+item.index+'-'+i}>
              {item.children && item.children.map((subitem, ii) => {
                if (OuterRedirect.hasOwnProperty(subitem.path)) {
                  return (
                    <li id={`menu-item-`+item.index+'_'+ii} key={`menu-item-`+item.index+'_'+ii} className="menu-item" onClick={(e) => {CloseMenu();}}>
                      <span className="menu-icon menu-icon-empty"></span>
                      <a href={OuterRedirect[subitem.path]} className="menu-text" target="_blank" rel="noreferrer noopener">
                        <p>{subitem.text}</p>
                      </a>
                    </li>
                  )
                }
                else {
                  return (
                    <li id={`menu-item-`+item.index+'_'+ii} key={`menu-item-`+item.index+'_'+ii} className="menu-item" onClick={(e) => {e.preventDefault();CloseMenu();}}>
                      <span className="menu-icon menu-icon-empty"></span>
                      <Link to={subitem.path} className="menu-text">
                        <p>{subitem.text}</p>
                      </Link>
                    </li>
                  )
                }
              })}
            </span>
          )
        })
    )
  }

  switch (jsonStatus) {
    case null:
      return <ul className="menu"><li className="Loading-nav b-red ml-10"><Loaders type="dots" color="b-white"/></li></ul>;
    case false:
      return (
        <nav className="menu-transform-items b-white" id="sidenav">
          <ul className="menu-items">
            <li className="menu-item current">
              <Svg name="BackArrow" class="menu-icon"/>
              <span className="menu-text">
                <p>Bank Rumia</p>
              </span>
              <div className="childs-icon"></div>
            </li>
          </ul>
        </nav>
      );
    case true:
      return (
        <RenderNavs/>
      );
    default:
      break;
  }
};
export default NavSide;