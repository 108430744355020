import React from "react";
import { environment } from "./../../environment";
import TextLinks from "../../components/TextLinks";
import Image from "../../components/Image";

const Benefits = (params) => {
  if (
    window.origin.includes(environment.localhost) ||
    window.origin.includes(environment.devName)
  ) {
    console.log("Benefits");
    console.log(params);
  }

  let colorBack = "";
  if (
    params.block["en"]["Background"] !== "" &&
    params.block["en"].hasOwnProperty("Background")
  ) {
    colorBack = "b-" + params.block["en"]["Background"];
  }
  let innerSection = "benefits";
  let innerContainer = "container d-flex f-column jc-center";
  if (
    params.block["en"]["InnerBackground"] !== "" &&
    params.block["en"].hasOwnProperty("InnerBackground")
  ) {
    if (params.block["en"]["InnerBackground"] === true) {
      innerContainer = innerContainer + " " + colorBack;
    } else {
      innerSection = innerSection + " " + colorBack;
    }
  }

  function BenefitsItem({ item }) {
    return (
      <div className="benefits-item">
      {item.Title && !item.Image && 
        <h3>{item.Title}</h3>
      }
      {item.Image && 
        <Image src={item.Image} alt={item.Title ? item.Title : "Bank Rumia Spółdzielczy - benefit"}/>
      }
      {item.Text && 
        <div className="benefit-text text">
          <TextLinks text={item.Text} line={params.line} />
        </div>
      }
      </div>
    );
  }

  return (
    <section className={innerSection}>
      <div className="wrapper">
        <div className={innerContainer}>
          {params.block["en"]["Title"] && (
            <h2 className="mb-20">{params.block["en"]["Title"]}</h2>
          )}
          <div className={`benefits-item-wrapper ${params.block["en"]["AlignLeft"] ? "align-left" : ""}`}>
            {params.block["en"].Item.map((item, index) => {
              return <BenefitsItem item={item} key={index} />
            })}
          </div>
        </div>
      </div>
    </section>
  )
};

export default Benefits;
