// import { upload } from '@testing-library/user-event/dist/types/utility';
import React, { useRef, useState } from 'react';
import log from '../log';

let MAX_COUNT = 3;
let MAX_SIZE = 5000000;
let ACCEPTED = [];
let formats = '';
let first = true;
let uploadedFilesOther = [];
let uploadedFilesCv = [];

const FileUploader = ({ maxFileSize, maxFiles, accepted, text, id, filesToSend, removeAllFiles, uploaded, setErrorMessage, setWrongFile }) => {

    //log('[FileUploader] maxFileSize : ' + maxFileSize + ' maxFiles: ' + maxFiles + ' accepted: ' + accepted + ' id: ' + id + ' filesToSend: ' + typeof filesToSend + ' removeAllFiles: ' + removeAllFiles + ' uploaded: ' + uploaded);

    const inputRef = useRef(null);
    const [fileLimit, setFileLimit] = useState(false);
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    if (first) {
        first = false;

        if (maxFileSize) {
            MAX_SIZE = maxFileSize;
        }
        if (maxFiles) {
            MAX_COUNT = maxFiles;
        }
        if (accepted) {
            ACCEPTED = accepted;
            formats = ACCEPTED.reduce((prev, curr) => {

                return ((prev.startsWith('vnd') ? 'docx' : prev.substring(prev.search('/') + 1)) + ', ' + (curr.startsWith('vnd') ? 'docx' : curr.substring(curr.search('/') + 1))).toUpperCase();
            });
        }
    }
    if (uploaded) {
        if (Array.isArray(uploaded)) {
            if (id.includes("CV")) {
                uploadedFilesCv = uploaded;
            } else {
                uploadedFilesOther = uploaded;
            }
            
        }
        else {
            if (id.includes("CV")) {
                uploadedFilesCv.length = 0;
                if (typeof uploaded.name === 'string') {    //object is an file
                    uploadedFilesCv.push(uploaded);
                }
            } else {
                uploadedFilesOther.length = 0;
                if (typeof uploaded.name === 'string') {    //object is an file
                    uploadedFilesOther.push(uploaded);
                }
            }
        }
    }

    const handleUploadClick = (e) => {
        if (id.includes("CV")) {
            if (fileLimit && uploadedFilesCv.length !== 0) {
                return;
            }
            inputRef.current?.click();
        } else {
            if (fileLimit && uploadedFilesOther.length !== 0) {
                return;
            }
            inputRef.current?.click();
        }
    };

    const handleFileChange = (e, filesArray) => {

        if (!e.target.files) {
            return;
        }
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        let filesToUploadCv = [];
        let filesToUploadOther = [];
        let uploaded = [...filesArray];

        chosenFiles.some((file) => {

            const found = uploaded.findIndex((f) => f.name === file.name);

            if (found === -1) {
                if (uploaded.length >= MAX_COUNT) {
                    if (setErrorMessage) {
                        setErrorMessage(`Możesz przesłać maksymalnie ${MAX_COUNT} plik${MAX_COUNT > 1 ? 'i' : ''}.`);
                    } else {
                        alert(`Możesz przesłać maksymalnie ${MAX_COUNT} plik${MAX_COUNT > 1 ? 'i' : ''}.`);
                    }
                    
                    return true;
                }
                else {
                    uploaded.push(file);
                    if (id.includes("CV")) {
                        filesToUploadCv.push(file);
                    } else {
                        filesToUploadOther.push(file);
                    }
                }
            }
            else {
                if (setErrorMessage) {
                    setErrorMessage(`Plik jest już załączony do wiadomości.`);
                } else {
                    alert(`Plik jest już załączony do wiadomości.`);
                }
            }
            return false;
        });

        if ((id.includes("CV") && filesToUploadCv.length) || (!id.includes("CV") && filesToUploadOther.length)) {

            let final = [];
            let uploadFinal;

            if (id.includes("CV")) {
                uploadFinal = filesToUploadCv;
            } else {
                uploadFinal = filesToUploadOther;
            }

            uploadFinal.forEach((file, i) => {
                let newError = "";

                const MAX_SIZE_LABEL = parseInt(MAX_SIZE * 0.000001);

                if (file.size > MAX_SIZE) {
                    newError = `Plik ${file.name} jest zbyt duży. Maksymalny rozmiar to ${MAX_SIZE_LABEL} Mb.`;
                }
                let fmt = ACCEPTED.find(elm => file.type === elm);

                if (!fmt) {
                    newError = `Niedozwolony typ pliku. Dozwolony format: PDF, DOCX`;
                }
                if (newError.length) {
                    if (setErrorMessage) {
                        setErrorMessage(newError);
                        if (!fmt) {
                            if (id.includes("CV")) {
                                setWrongFile("CV")
                            } else {
                                setWrongFile("Other")
                            }
                        }
                    }
                }
                else {
                    final.push(file);
                }
            });
            if (id.includes("CV")) {
                uploadedFilesCv = [...uploadedFilesCv, ...final];
                setFileLimit(uploadedFilesCv.length >= MAX_COUNT);
                filesToSend([...uploadedFilesCv]);
            } else {
                uploadedFilesOther = [...uploadedFilesOther, ...final];
                setFileLimit(uploadedFilesOther.length >= MAX_COUNT);
                filesToSend([...uploadedFilesOther]);
            }
        }
    };

    const removeAttachment = (fileName, filesArray) => {
        let uploaded = filesArray;
        uploaded.splice(uploaded.findIndex((f) => f.name === fileName), 1);
        if (uploaded.length == 0) {
            filesToSend([])
        } else {
            filesToSend([...uploaded])
        }
        setFileLimit(false);
        forceUpdate();
    };

    const clear = () => {

        if ((id.includes("CV") && uploadedFilesCv.length && removeAllFiles) || (!id.includes("CV") && uploadedFilesOther.length && removeAllFiles)) {
            if (id.includes("CV")) {
                uploadedFilesCv = [];
            } else {
                uploadedFilesOther = [];
            }
            setFileLimit(false);
        }
    };

    let uploadedFilesFinal;
    if (id.includes("CV")) {
        uploadedFilesFinal = uploadedFilesCv;
    } else {
        uploadedFilesFinal = uploadedFilesOther;
    }

    return (
        <div className={`file-upload${!fileLimit || uploadedFilesFinal.length == 0 ? '' : ' disabled'}`}>
            <p>Załącz {text}</p>
            {/* custom button to select and upload a file */}
            <button
                type="button"
                className={`button primary`}
                onClick={(e) => { handleUploadClick(e) }}
            >{'Wybierz plik'}</button>
            <input type="hidden" value="" disabled
                onClick={clear()}
                onChange={e => (e.target.value = null)}
            />
            <ul>
                {[...uploadedFilesFinal].map((file, i, arr) => (
                    <li key={i}>
                        {file.name}<span
                            onClick={() => { removeAttachment(file.name, [...arr]) }}
                        >&#215;</span>
                    </li>
                ))
                }
            </ul>
            {/* input has `display: hidden` style */}
            <input
                type="file"
                ref={inputRef}
                onChange={(e) => { handleFileChange(e, [...uploadedFilesFinal]); e.target.value = null }}
                style={{ display: 'none' }}
                multiple
                accept={ACCEPTED}
            />
        </div>
    );
}
export default FileUploader;