import React, { useState, useEffect, useRef } from "react";
import Image from "../../components/Image";
import { environment } from "./../../environment";
import TextLinks from "../../components/TextLinks";

const ManagementBox = (params) => {
  if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
    console.log("Management-box");
    console.log(params);
  }

  let colorBack = "";
  if (params.block["en"]["Background"] !== "" && params.block["en"].hasOwnProperty("Background")) {
    colorBack = "b-" + params.block["en"]["Background"];
  }
  let innerSection = "management-box";
  let innerContainer = "container d-flex f-column jc-center";
  if (params.block["en"]["InnerBackground"] !== "" && params.block["en"].hasOwnProperty("InnerBackground")) {
    if (params.block["en"]["InnerBackground"] === true) {
      innerContainer = innerContainer + " " + colorBack;
    } else {
      innerSection = innerSection + " " + colorBack;
    }
  }

  function ManagementBoxItem({item}) {
    const textRef = useRef(null)
    const [isClamped, setIsClamped] = useState(false)
    const [isClicked, setIsClicked] = useState(false)

    let altText = "";
    if (item.AltText) {
      altText = item.AltText;
    } else if (!item.AltText && item.Title) {
      altText = item.Title;
    } else {
      altText = "Bank Rumia image";
    }

    useEffect(() => {
      if(textRef.current != null) {
        if (textRef.current.clientHeight !== textRef.current.scrollHeight) {
          setIsClamped(true)
        } else {
          setIsClamped(false)
        }
      }
    }, [])
    
    return (
      <div className="management-box-item">
        {item["Image"] && item["Image"].hasOwnProperty("path") &&
          item["Image"]["path"] !== "" 
          ? (<Image src={item["Image"]} alt={altText} />) : ("")
        }
        <div className="management-body">
          {item.Name && <h3 className="mb-10">{item.Name}</h3>}
          {item.Position && <h5 className="mb-10">{item.Position}</h5>}
          {item.Text && 
            <span ref={textRef} className={`management-text text ${isClicked ? 'show-more' : ''}`}>
              <TextLinks text={item.Text} line={params.line} />
            </span>
          }
          {isClamped && <button className="btn-show-more" onClick={() => {setIsClicked(!isClicked)}}>{isClicked ? 'pokaż mniej' : 'pokaż więcej'}</button>}
        </div>
      </div>
    );
  }

  return (
    <section className={innerSection}>
      <div className="wrapper">
        <div className={innerContainer}>
          {params.block["en"]["Title"] && (
            <h2 className="mb-20">{params.block["en"]["Title"]}</h2>
          )}
          <div className="management-box-item-wrapper">
            {params.block["en"].Item.map((item, index) => (
              <ManagementBoxItem key={index} item={item} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ManagementBox;
