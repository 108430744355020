import React from "react";
import { environment } from "./environment";
import Links from "./components/Links";
import Svg from "./components/Svg";
import Nav from "./NavFooter.js";

function Foo(params) {
  if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
    console.log('Footer');
    console.log(params);
  }

  return (
    <section className="footer">
      <div className="wrapper">
        <div className="container d-flex f-column jc-between">
          <div className="container nav-content t-footer d-flex jc-between b-color2">
          <div className="col-9 left-foot d-flex">
            <nav className="main-nav">
              <Nav line={params.line} type="footer"/>
            </nav>
          </div>
          <div className="col-3 left-foot d-flex">
            <div className="r-footer">
              <div className="top-foot">
                <ul className="menu"><li><Links line={params.line} url="/kontakt/" name="KONTAKT" /></li></ul>
                <div className="mt-20">
                  <h3 className="big-h pt-10"><a href="tel:800888888" title="800 888 888">800 888 888</a></h3><br/>
                </div>
                <div className="mt-10">
                  <p className="small-p">Dla dzwoniących zza granicy</p>
                  <h4><a href="tel:616472846" title="61 647 28 46">61 647 28 46</a></h4><br/>
                </div>
              </div>
              <div className="bottom-foot">
                <div className="soc-menu ta-center">
                  <div className="f-wrap f-column d-flex ai-start ta-center">
                    <a href="/kontakt/" title="Oddziały Banku">
                      <Svg name="MapTick" />
                      <span>Oddziały Banku</span>
                    </a>
                    <a href="/kontakt/" title="Wyślij wiadomość">
                      <Svg name="svgMail" />
                      <span>Wyślij wiadomość</span>
                    </a>
                    <a href="https://www.facebook.com/bankrumia" target="_blank" rel="noreferrer" title="Odwiedź nas na Facebook">
                      <Svg name="svgFB" />
                      <span>Odwiedź nas</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="code-foot">
                SWIFT/BIC: <span>GBWCPLPP</span>
              </div>
            </div>
          </div>
        </div>
        <div className="b-footer b-white">
          <div className="container d-flex ai-center">
            <p className="mr-30" title="Wszelkie prawa zastrzeżone">2023 © Bank Rumia</p>
            <div className="f-links d-flex ai-center">
              <Links line={params.line} url="/informacje-prawne/polityka-cookies/" name="Polityka cookies" />
              {/* <Links line={params.line} url="/nota-prawna/" name="Nota prawna" /> */}
            </div>
            <div className="powered">
              <a href="https://qrqs.eu/" target="_blank" rel="noreferrer" title="Quercus – Software on demand, on time">
                <span>powered by </span>
                <Svg name="svgQRQS" />
              </a>
            </div>
          </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Foo;