import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { environment } from "./environment";
import App from "./Main";
import Header from "./Header";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop"
import Cookies from "./Cookies.js";

const container = document.getElementById("page-wrapper");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route path="*" element={
        <>
          <header id="header"><div id="infobar"></div><Header line="online"/></header>
          <main><App /></main>
          <footer id="footer"><Footer line="online"/></footer>
        </>
      } />
    </Routes>
    <Cookies type="policy"/>
  </BrowserRouter>
);