import React, { useState, useEffect } from "react";
import TextLinks from "../../components/TextLinks";
import { Link } from "react-router-dom";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { environment } from "./../../environment";
import Banner from "../topBanner/TopBanner";

function NewsDetails(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('NewsDetails')
        console.log(params)
    }
    
    /*let id = params.block.path.split("/").pop();
        id = id.substring(0, id.length - 4);*/
    let Images = params.block['en'].hasOwnProperty('ImageBanner') ? (params.block['en']['ImageBanner'].hasOwnProperty('link') ? params.block['en']['ImageBanner']['link'] : environment.defaultImage) : environment.defaultImage,
        Tag = params.block['en'].hasOwnProperty('Tags') ? params.block['en']['Tags'] : [],
        Title = params.block['en'].hasOwnProperty('Title') ? params.block['en']['Title'] : '',
        Teaser = params.block['en'].hasOwnProperty('Teaser') ? params.block['en']['Teaser'] : '',
        Text = params.block['en'].hasOwnProperty('Text') ? params.block['en']['Text'] : '';
 
    let currentTimestamp = new Date();
    if (params.block['en']['Date'] && params.block['en']['Date'] !== '') currentTimestamp = new Date(params.block['en']['Date']);
    let date = [currentTimestamp.getDate(), currentTimestamp.getMonth()+1, currentTimestamp.getFullYear().toString().slice(-2)];

    let [catData, setCatData] = useState([]);
    useParams();
    useEffect(() => {
        let fetchCats = async () => {
            let url = null;
            if (window.location.origin.includes(environment.cmsName)) {
                url = window.location.origin + environment.categoriesJson;
            }
            else if (window.location.origin.includes(environment.localhost)) {
                url = environment.siteUrlDev + environment.categoriesJson;
            }
            else {
                url = window.location.origin + environment.categoriesJson;
            }
            let response = "";
            try {
                response = await Axios(url);
            } catch (err) {
                response = "error";
            }
            if (response.hasOwnProperty('data')) setCatData(response.data);
        };
        fetchCats();
    },[]);
    function Tags() {
        const splitTags = Tag.split(',');
        return (
            splitTags.map((tag) => {
                let name = catData[tag],
                    tagLast = tag.split('/'),
                    nameTag = tagLast[tagLast.length - 2];
                let pathOriginList = window.location.pathname.split('item/')[0],
                    href = pathOriginList+'#'+nameTag;
                if (!name || name === '') name = nameTag;
                if (params.line === 'online') {
                    return (<Link key={href} to={href} className="button tag-type mr-15">{name}</Link>) 
                }
                else {
                    return (<a key={href} href={href} className="button tag-type mr-15" data-tags={tagLast[tagLast.length - 2]}>{name}</a>)  
                }
            })
        )
    }
    
    function TopBox() {
        return (
            <div className="cats-box d-flex ai-center jc-center">
                {params.block['en']['Tags'] && 
                    <Tags/>
                }
            </div>
        )
    }
    return (
        <>
            <Banner out={true} title={Title} teaser={Teaser} image={Images} block={params.block} line={params.line}></Banner>
            <section className="news-details">
                <div className="wrapper">
                    <div className="container b-white">
                        <div className="top-box d-flex ai-center jc-between mb-15">
                            <TopBox />
                            {params.block['en']['Date'] && 
                                <div className="date-box">
                                    <p className="c-color1">{date[0]}/{date[1]}/{date[2]}</p> 
                                </div>
                            }
                        </div>
                        {/*params.block['en']['Title'] &&
                            <div className="title-box">
                                <h1>{params.block['en']['Title']}</h1>
                            </div>
                        */}
                        {/*params.block['en']['Teaser'] && 
                            <div className="text teaser mt-20">
                                <TextLinks text={params.block['en']['Teaser']} line={params.line} />
                            </div>
                        */}
                        {params.block['en']['Text'] && 
                            <div className="text mt-20">
                                <TextLinks text={Text} line={params.line} />
                            </div>
                        }
                    </div>
                </div>
            </section>
        </>
    );
}

export default NewsDetails;