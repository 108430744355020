import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { environment } from "./environment";
import Loaders from "./Loaders.js";
import Svg from "./components/Svg";

const Redirect = {
  "aktualnosci/": '/aktualnosci/',
  "biznes/": '/biznes/'
};
const OuterRedirect = {
  "outer/": 'https://outer.com/',
};

function Nav(params) {
  if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
    console.log('Build Nav')
  }
  let [navData, setNavData] = useState("");
  useParams();
  let [jsonStatus, setJsonStatus] = useState(null);
  useEffect(() => {
    let fetchNavs = async () => {
      let url = null;
      if (window.location.origin.includes(environment.cmsName)) {
        url = window.location.origin + environment.navJson;
      }
      else if (window.location.origin.includes(environment.localhost)) {
        url = environment.siteUrlDev + environment.navJson;
      }
      else {
        url = window.location.origin + environment.navJson;
      }
      let response = "";
      try {
        response = await Axios(url);
        setJsonStatus(true);
      } catch (err) {
        setJsonStatus(false);
        response = "error";
        console.log('error get nav: ' + err)
      }
      if (response.hasOwnProperty('data')) {
        setNavData(response.data);
      }
      else {
        setJsonStatus(false);
      }
    };
    fetchNavs();
  },[]);
  if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
    console.log(navData);
  }

  function Svgs(svg) {
    let names = '';
    switch (svg.name.toLowerCase()) {
      case "bezpieczeństwo":
        names = 'safe';
        break;
      case "aktualności":
        names = 'news';
        break;
      case "nieruchomości":
        names = 'house';
        break;
      default:
        names = 'SearchIcon';
        break;
    }
    return (
      <Svg name={names} />
    )
  }
  function removeSlideBodyActive() {
    if (document.body.classList.contains('dropdown-active')) {
      document.body.classList.remove("dropdown-active");
    }
    if (document.body.classList.contains('slideup-active')) {
      document.body.classList.remove("slideup-active");
    }
  }
  function RedirectLink(item) {
    if (item.line === 'online') {
      if (item.child < 1 || item.button) {
        if (Redirect.hasOwnProperty(item.link)) {
          return (
            <Link to={Redirect[item.link]} title={item.text} className="button light icon-btn d-flex ai-center jc-center mt-10">
                <Svgs name={item.text} />
                <span>{item.text}</span>
            </Link>
          )
        }
        else {
          return (
            <Link to={`/`+item.link} title={item.text} className="button light icon-btn d-flex ai-center jc-center mt-10" onClick={(e) => {removeSlideBodyActive()}}>
                <Svgs name={item.text} />
                <span>{item.text}</span>
            </Link>
          )
        }
      }
      else {
        return (
          <span className="menu-item-href">
            {item.text}
          </span>
        )
      }
    }
    else {
      if (item.child < 1 || item.button) {
        if (Redirect.hasOwnProperty(item.link)) {
          return (
            <a href={Redirect[item.link]} className="button light icon-btn d-flex ai-center jc-center mt-10">
              <Svgs name={item.text} />
              <span>{item.text}</span>
            </a>
          )
        }
        else {
          return (
            <a href={`/`+item.link} className="button light icon-btn d-flex ai-center jc-center mt-10" onClick={(e) => {removeSlideBodyActive()}}>
              <Svgs name={item.text} />
              <span>{item.text}</span>
            </a>
          )
        }
      }
      else {
        return (
          <span className="menu-item-href">
            {item.text}
          </span>
        )
      }
    }
  }
  function SpecialNavBox(param) {
    let i = param.i;
    let item = param.item;
    if (params.line === "offline") {
      return (
        <li id={`menu-item-`+i} key={`menu-item-`+i+'_'+i} className={'special dropdown'+(item.hasOwnProperty('navShowFooter') ? (item.navShowFooter === 'false' ? ' hidden' : '') : '')}>
          <RedirectLink link={item.path} text={item.text} line="offline" child={item.children.length} button={(item.hasOwnProperty('navButton') ? (item.navButton === 'true' ? true : false) : false)} />
          {(item.children && item.children.length > 0) &&
            (<ul className="submenu">
              {item.children.map((subitem, ii) => {
                if (OuterRedirect.hasOwnProperty(subitem.path)) {
                  return (<li id={`menu-item-`+i+'_'+ii} key={`menu-item-`+i+'_'+ii} className={'dropdown'+(subitem.hasOwnProperty('navShowFooter') ? (subitem.navShowFooter === 'false' ? ' hidden' : '') : '')}>
                    <a href={OuterRedirect[subitem.path]} target="_blank" rel="noreferrer" className="menu-item-href">
                      {subitem.text}
                    </a>
                  </li>)
                }
                else {
                  return (<li id={`menu-item-`+i+'_'+ii} key={`menu-item-`+i+'_'+ii} className={'dropdown'+(subitem.hasOwnProperty('navShowFooter') ? (subitem.navShowFooter === 'false' ? ' hidden' : '') : '')} onClick={(e) => {removeSlideBodyActive()}}>
                    <a href={`/`+subitem.path} className="menu-item-href">
                      {subitem.text}
                    </a>
                  </li>)
                }
              })}
            </ul>)
          }
        </li>)
    }
    else {
      return (
        <li id={`menu-item-`+i} key={`menu-item-`+i+'_'+i} className={'special dropdown'+(item.hasOwnProperty('navShowFooter') ? (item.navShowFooter === 'false' ? ' hidden' : '') : '')}>
          <RedirectLink link={item.path} text={item.text} line="online" child={item.children.length} button={(item.hasOwnProperty('navButton') ? (item.navButton === 'true' ? true : false) : false)}/>
          {(item.children && item.children.length > 0) &&
            (<ul className="submenu">
              {item.children.map((subitem, ii) => {
                if (OuterRedirect.hasOwnProperty(subitem.path)) {
                  return (<li id={`menu-item-`+i+'_'+ii} key={`menu-item-`+i+'_'+ii} className={'dropdown'+(subitem.hasOwnProperty('navShowFooter') ? (subitem.navShowFooter === 'false' ? ' hidden' : '') : '')}>
                    <a href={OuterRedirect[subitem.path]} target="_blank" rel="noreferrer" className="menu-item-href">
                      {subitem.text}
                    </a>
                  </li>)
                }
                else {
                  return (<li id={`menu-item-`+i+'_'+ii} key={`menu-item-`+i+'_'+ii} className={'dropdown'+(subitem.hasOwnProperty('navShowFooter') ? (subitem.navShowFooter === 'false' ? ' hidden' : '') : '')} onClick={(e) => {removeSlideBodyActive()}}>
                    <Link to={`/`+subitem.path} className="menu-item-href">
                      {subitem.text}
                    </Link>
                  </li>)
                }
              })}
            </ul>)
          }
        </li>)
    }
  }
  function RenderNavs() {
    return (
      params.line === "offline"
      ? navData.map((item, i) => {
        let specialNavBox = ''
        if ((item.hasOwnProperty('navShowFooter') ? (item.navShowFooter === 'false' ? true : false) : false) || i === 5) {
          return ''
        }
        if (i === 4) {
          specialNavBox = <SpecialNavBox i={5} item={navData[5]}/>
        }
        return (
          <div className="footer-item-group" key={`menu-item-`+i+'_'+i}>
          <li id={`menu-item-`+i} className={'dropdown'+(item.hasOwnProperty('navShowFooter') ? (item.navShowFooter === 'false' ? ' hidden' : '') : '')}>
              <RedirectLink link={item.path} text={item.text} line="offline" child={item.children.length} button={(item.hasOwnProperty('navButton') ? (item.navButton === 'true' ? true : false) : false)}/>
              {(item.children && item.children.length > 0) &&
                (<ul className="submenu">
                  {item.children.map((subitem, ii) => {
                    if (OuterRedirect.hasOwnProperty(subitem.path)) {
                      return (<li id={`menu-item-`+i+'_'+ii} key={`menu-item-`+i+'_'+ii} className={'dropdown'+(subitem.hasOwnProperty('navShowFooter') ? (subitem.navShowFooter === 'false' ? ' hidden' : '') : '')}>
                        <a href={OuterRedirect[subitem.path]} target="_blank" rel="noreferrer" className="menu-item-href">
                          {subitem.text}
                        </a>
                      </li>)
                    }
                    else {
                      return (<li id={`menu-item-`+i+'_'+ii} key={`menu-item-`+i+'_'+ii} className={'dropdown'+(subitem.hasOwnProperty('navShowFooter') ? (subitem.navShowFooter === 'false' ? ' hidden' : '') : '')} onClick={(e) => {removeSlideBodyActive()}}>
                        <a href={`/`+subitem.path} className="menu-item-href">
                          {subitem.text}
                        </a>
                      </li>)
                    }
                  })}
                  
                </ul>)
              }
            </li>
            {specialNavBox}
            </div>)
        })
      : navData.map((item, i) => {
          let specialNavBox = ''
          if ((item.hasOwnProperty('navShowFooter') ? (item.navShowFooter === 'false' ? true : false) : false) || i === 5) {
            return ''
          }
          if (i === 4) {
            specialNavBox = <SpecialNavBox i={5} item={navData[5]}/>
          }
          return (
            <div className="footer-item-group" key={`menu-item-`+i+'_'+i}>
            <li id={`menu-item-`+i} className={'dropdown'+(item.hasOwnProperty('navShowFooter') ? (item.navShowFooter === 'false' ? ' hidden' : '') : '')}>
              <RedirectLink link={item.path} text={item.text} line="online" child={item.children.length} button={(item.hasOwnProperty('navButton') ? (item.navButton === 'true' ? true : false) : false)} />
              {(item.children && item.children.length > 0) &&
                (<ul className="submenu">
                  {item.children.map((subitem, ii) => {
                    if (OuterRedirect.hasOwnProperty(subitem.path)) {
                      return (<li id={`menu-item-`+i+'_'+ii} key={`menu-item-`+i+'_'+ii} className={'dropdown'+(subitem.hasOwnProperty('navShowFooter') ? (subitem.navShowFooter === 'false' ? ' hidden' : '') : '')}>
                        <a href={OuterRedirect[subitem.path]} target="_blank" rel="noreferrer" className="menu-item-href">
                          {subitem.text}
                        </a>
                      </li>)
                    }
                    else {
                      return (<li id={`menu-item-`+i+'_'+ii} key={`menu-item-`+i+'_'+ii} className={'dropdown'+(subitem.hasOwnProperty('navShowFooter') ? (subitem.navShowFooter === 'false' ? ' hidden' : '') : '')} onClick={(e) => {removeSlideBodyActive()}}>
                        <Link to={`/`+subitem.path} className="menu-item-href">
                          {subitem.text}
                        </Link>
                      </li>)
                    }
                  })}
                  
                </ul>)
              }
            </li>
            {specialNavBox}
            </div>)
        }
      )
    )
  }

  switch (jsonStatus) {
    case null:
      return <ul className="menu"><li className="Loading-nav b-color2 ml-10"><Loaders type="dots" color="b-color2"/></li></ul>;
    case false:
      return <ul className="menu"><li className="Loading-nav b-color2"><a href="/" className="menu-item-href">Strona Główna</a></li></ul>;
    case true:
      return (
        <>
          <ul className="menu d-flex f-wrap">
            <RenderNavs/>
            {/*<li id="menu-item-last">
              <a href="/nieruchomosci/" target="_blank" title="Nieruchomości" className="button light icon-btn d-flex ai-center jc-center mt-10">
                <Svg name="house" />
                <span>Nieruchomości</span>
              </a>
              </li> 
            */} 
          </ul>
        </>
      );
    default:
      break;
  }
};
export default Nav;