import React from "react";
import Cookie from "universal-cookie";
import { Link } from "react-router-dom";
import { environment } from "./environment";

function Cookies(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('Cookies')
        console.log(params)
    }

    function setPolicy(name, days) {
        const cookiess = new Cookie();
        let dayTo = new Date(Date.now()+days*86400000);
        cookiess.set(name, true, { path: '/', expires: dayTo});
        setTimeout(function(){ 
            var removeInfo = document.getElementById('cookie-info');
            if (removeInfo) {
                removeInfo.remove();
            }
        }, 1);
    }

    if (params.type === 'policy') {
        const cookiess = new Cookie();
        if ( !cookiess.get('__'+environment.cookieName+'_pp') || cookiess.get('__'+environment.cookieName+'pp') === '') {
            return (
                <div id="cookie-info">
                    <div className="row container b-color2h">
                        <div className="d-flex ai-center jc-between text sm-p">
                            <p className="elem-pl pr-30">
                                Na tej stronie stosowane są pliki cookies (tzw. ciasteczka). Dzięki nim możemy dostosować treści do indywidualnych potrzeb odwiedzających. <br/>Zapoznaj się z <Link to="/informacje-prawne/polityka-cookies/">Polityką Cookies</Link>
                            </p>
                            <Link to="#" onClick={(e) => {e.preventDefault();setPolicy('__'+environment.cookieName+'_pp', 365)}} className="button light">ZAAKCEPTUJ</Link>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return ('')
        }
    }   
}

export default Cookies;