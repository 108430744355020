import React from "react";
import { environment } from "./../../environment";
import Links from "../../components/Links";

const Button = (params) => {
  if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
      console.log("Button portlet");
      console.log(params);
  }
  
  let Title = params.block['en'].hasOwnProperty('Title') ? params.block['en']['Title'] : '',
      HideTitle = (params.block['en'].hasOwnProperty('HideTitle') ? (params.block['en']['HideTitle'] ? 'true' : 'false') : 'false'),

      Choice = params.block['en'].hasOwnProperty('Choice') ? params.block['en']['Choice'] : 'empty',
      ChoiceIs = Choice === 'empty' ? 'empty' : (Choice.hasOwnProperty('Button') ? 'button' : (Choice.hasOwnProperty('Link') ? 'link' : 'empty')),

      ButtonLink = Choice === 'empty' ? '' : (Choice.hasOwnProperty('Button') ? (Choice['Button'].hasOwnProperty('ButtonLink') ? Choice['Button']['ButtonLink'] : '') : ''),
      ButtonName = Choice === 'empty' ? '' : (Choice.hasOwnProperty('Button') ? (Choice['Button'].hasOwnProperty('ButtonName') ? Choice['Button']['ButtonName'] : '') : ''),
      ButtonPosition = Choice === 'empty' ? 'left' : (Choice.hasOwnProperty('Button') ? (Choice['Button'].hasOwnProperty('ButtonPosition') ? Choice['Button']['ButtonPosition'] : 'left') : 'left'),
      ButtonType = Choice === 'empty' ? 'primary' : (Choice.hasOwnProperty('Button') ? (Choice['Button'].hasOwnProperty('ButtonType') ? Choice['Button']['ButtonType'] : 'primary') : 'primary'),
      ButtonNewOpen = Choice === 'empty' ? 'false' : (Choice.hasOwnProperty('Button') ? (Choice['Button']['NewTab'] ? 'true' : 'false') : 'false'),

      Link = Choice === 'empty' ? '' : (Choice.hasOwnProperty('Link') ? (Choice['Link'].hasOwnProperty('Link') ? Choice['Link']['Link'] : '') : ''),
      LinkName = Choice === 'empty' ? '' : (Choice.hasOwnProperty('Link') ? (Choice['Link'].hasOwnProperty('LinkName') ? Choice['Link']['LinkName'] : '') : ''),
      LinkPosition = Choice === 'empty' ? 'left' : (Choice.hasOwnProperty('Link') ? (Choice['Link'].hasOwnProperty('LinkPosition') ? Choice['Link']['LinkPosition'] : 'left') : 'left'),
      LinkNewOpen = Choice === 'empty' ? 'false' : (Choice.hasOwnProperty('Link') ? (Choice['Link']['NewTab'] ? 'true' : 'false') : 'false');

  return (
    <section aria-label="section" className="button-portlet">
      <div className="wrapper">
        <div className="container">
          {Title && HideTitle !== 'true' &&
            <div className="title-box mb-15">
              <h2>{Title}</h2>
            </div>
          }
          <div className="bottom-box">
            {ChoiceIs === 'button' && 
              <div className={'button-box w-100 ta-'+ButtonPosition}>
                <Links line={params.line} url={ButtonLink} class={'button ' + ButtonType} name={ButtonName} target={ButtonNewOpen}/>
              </div>
            }
            {ChoiceIs === 'link' && 
              <div className={'link-box w-100 ta-'+LinkPosition}>
                <Links line={params.line} url={Link} class="link-name" name={LinkName} target={LinkNewOpen}/>
              </div>
            }
          </div>
        </div>
      </div>
    </section>
  );
};

export default Button;
