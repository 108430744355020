import React, { useEffect } from "react"
import { environment } from "./../../environment"

const CalcIframe = (params) => {
  if (
    window.origin.includes(environment.localhost) ||
    window.origin.includes(environment.devName)
  ) {
    console.log("Calc-iframe")
    console.log(params)
  }

  let colorBack = ""
  if (
    params.block["en"]["Background"] !== "" &&
    params.block["en"].hasOwnProperty("Background")
  ) {
    colorBack = "b-" + params.block["en"]["Background"]
  }
  let innerSection = "calc-box"
  let innerContainer = "container d-flex f-column jc-center"
  if (
    params.block["en"]["InnerBackground"] !== "" &&
    params.block["en"].hasOwnProperty("InnerBackground")
  ) {
    if (params.block["en"]["InnerBackground"] === true) {
      innerContainer = innerContainer + " " + colorBack
    } else {
      innerSection = innerSection + " " + colorBack
    }
  }

  useEffect(() => {
    const calcId = params.block['en']['CalcId'] || "bsi-1d99dd877c383b8079cf4daadc23e5b21e16f42cdf48d44c75f00de478f07179"
    
    var _bsi_iframes = []
    _bsi_iframes.push(
      calcId
    )

    function readValueFromUrl(name, url) {
      if (!url) url = window.location.href
      name = name.replace(/[\[\]]/g, "\\$&")
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url)
      if (!results) return null
      if (!results[2]) return ""
      return decodeURIComponent(results[2].replace(/\+/g, " "))
    }

    function calc() {
      _bsi_iframes.forEach(function (bsiId) {
        const iframeTimeout = document.getElementById(bsiId)

        if (iframeTimeout) {
          var match = iframeTimeout.src.match(
            /(http|https):\/\/(www[0-9]?\.)?(.[^/:]+)/i
          )
          var message =
            "amount=" +
            readValueFromUrl("amount") +
            "&period=" +
            readValueFromUrl("period")
          var url = null

          if (
            match != null &&
            match.length > 3 &&
            typeof match[3] === "string" &&
            match[3].length > 0
          ) {
            url = "https" + "://" + match[3]
          }

          function resize(event) {
            if (event?.origin !== url) {
              return
            }

              if (iframeTimeout) {
                iframeTimeout.style.height = event.data.height + "px"
              }
          }

          function postResize() {
            if (iframeTimeout.contentWindow.postMessage && url) {
              iframeTimeout.contentWindow.postMessage("resize", url)
            }
          }

          const startPolling = () => {
            if (iframeTimeout) {
              iframeTimeout.contentWindow.postMessage(message, url)
              resize()
              return
            }
            setTimeout(startPolling, 1000)
          }
          iframeTimeout.onload = () => {
            startPolling()
          }

          window.addEventListener("message", resize, false)
          window.addEventListener("resize", postResize, false)
        }
      })
    }

    calc()
  }, [])

  return (
    <section className={innerSection}>
      <div className="wrapper">
        <div className={innerContainer}>
          {params.block["en"]["Title"] && (
            <h2 className="mb-20">{params.block["en"]["Title"]}</h2>
          )}
          {params.block["en"]["Code"] && (
            <div
              class="calc-code-box"
              dangerouslySetInnerHTML={{ __html: params.block["en"]["Code"] }}
            ></div>
          )}
        </div>
      </div>
    </section>
  )
}

export default CalcIframe
