import React, {useEffect, useCallback, useState, useRef} from 'react'
import { GoogleMap, useJsApiLoader, Marker, MarkerClusterer } from '@react-google-maps/api'; //https://react-google-maps-api-docs.netlify.app/#directionsrenderer
import { environment } from "../../environment";
import Svg from "../../components/Svg";

let locationPlacesBranches = [],
    locationListBranches = [],
    locationPlacesCash = [],
    locationListCash = [],
    activeMap = 'est',
    url = null;
    if (window.location.origin.includes(environment.cmsName)) {
        url = window.location.origin;
    }
    else if (window.location.origin.includes(environment.localhost)) {
        url = environment.siteUrlDev;
    }
    else {
        url = window.location.origin;
    }
fetch(url+environment.branches)
    .then(response => response.json())
    .then(data => {
        data.map(function (item, i) {
            
            const numbersPhone = item.phone.split(',');
            let opendaysName = item.working_days;

            if (opendaysName === "5") {
                opendaysName = "Poniedziałek - Piątek"
            }

            let date = new Date(),
                time = date.getHours() * 100 + date.getMinutes(),
                openTime = parseInt(item.open_hour.replace(':', '')),
                closeTime = parseInt(item.closed_hour.replace(':', '')),
                isOpened,
                isClosed;

            if (time >= openTime && time < closeTime) {
                isOpened = 'teraz otwarte';
            }
            else {
                isClosed = 'teraz zamknięte';
            }

            locationListBranches.push({lat: parseFloat(item.lat), lng: parseFloat(item.long), opendays: opendaysName, open: item.open_hour, closed: item.closed_hour, isOpen: isOpened, isClose: isClosed, email: item.email, phone: numbersPhone, phoneOffice: item.phone_office, label: item.branch_name, postCode: item.postcode, city: item.city_post, street: item.street})
            locationPlacesBranches.push({lat: parseFloat(item.lat), lng: parseFloat(item.long), label: item.branch_name})
            return null;
        })
    });
    
fetch(url+environment.cashDispensers)
    .then(response => response.json())
    .then(data => {
        data.map(function (item, i) {
            locationListCash.push({lat: parseFloat(item.lat), lng: parseFloat(item.long), label: item.bank, postCode: item.postcode, city: item.city_post, street: item.street})
            locationPlacesCash.push({lat: parseFloat(item.lat), lng: parseFloat(item.long), label: item.bank, street: item.street})
            return null;
        })
    });

function MapBox(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('Map-box')
        console.log(params)
    }

    //portlets scripts
    let colorBack = ''
    if (params.block['en']['FontColor'] !== '' && params.block['en'].hasOwnProperty('FontColor')) {
        colorBack = 'b-' + params.block['en']['FontColor']
    }
    let innerSection = 'map-box container';
    let innerContainer = 'container-wide';
    if (params.block['en']['InnerBackground'] !== '') {
        innerSection = innerSection + ' ' + colorBack;
    }
    //params url from another site and point to marker with params
    const queryParameters = new URLSearchParams(window.location.search),
        latUrl = queryParameters.get("lat"),
        lngUrl = queryParameters.get("lng"),
        typeUrl = queryParameters.get("type"),
        refMap = useRef('map-box d-flex f-wrap loading');
    let stopQuery = false;

    //map scripts
    const containerStyle = { //map style
        width: '100%',
        height: '800px'
    };

    let centerMap, centerMapEst;

    if (latUrl && lngUrl && typeUrl === "atm" && stopQuery === false) {
        centerMap = { //centered map after load with url params
            lat: parseFloat(latUrl),
            lng: parseFloat(lngUrl)
        };
    } 
    else {
        centerMap = { //centered map after load
            lat: 52.144,
            lng: 19.514
        };
    }

    if (latUrl && lngUrl && typeUrl === "est" && stopQuery === false) {
        centerMapEst = { //centered map after load with url params
            lat: parseFloat(latUrl),
            lng: parseFloat(lngUrl)
        };
    } else {
        centerMapEst = { //centered map after load
            lat: 54.548,
            lng: 18.012
        };
    }
    
    let APIKey = "";

    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        APIKey = environment.testMapApiKey // TEST API KEY ML
    } else {
        APIKey = environment.prodMapApiKey // PROD
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: APIKey
    })

    const [map, setMap] = useState(null),
        [activeEst, setActiveEst] = useState("active"),
        [activeAtm, setActiveAtm] = useState(""),
        [searchTerm, setSearchTerm] = useState(""),
        [locationSearchingListEst, setLocationSearchingListEst] = useState([]),
        [locationSearchingMapEst, setLocationSearchingMapEst] = useState([]),
        [locationSearchingListAtm, setLocationSearchingListAtm] = useState([]),
        [locationSearchingMapAtm, setLocationSearchingMapAtm] = useState([]),
        [curSection, setCurSection] = useState('');

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => { //searching input
            if (activeEst === "active") {
                setLocationSearchingListEst([]);
                locationListBranches.filter(
                    item =>
                    `${item.postCode + ' ' + item.city + ' ' + item.street + ' ' + item.label}`
                        .toUpperCase()
                        .indexOf(searchTerm.toUpperCase()) >= 0
                    )
                    .map((item, index) => {
                        return (
                            setLocationSearchingListEst(locationSearchingListEst => [...locationSearchingListEst, item])
                        )
                    })
            } else {
                setLocationSearchingListAtm([]);
                locationListCash.filter(
                    item =>
                    `${item.postCode + ' ' + item.city + ' ' + item.street + ' ' + item.label}`
                        .toUpperCase()
                        .indexOf(searchTerm.toUpperCase()) >= 0
                    )
                    .map((item, index) => {
                        return (
                            setLocationSearchingListAtm(locationSearchingListAtm => [...locationSearchingListAtm, item])
                        )
                    })
            }
        }, 500);
                
        if (map !== null) {
            setCurSection("")
            if (activeEst === "active") {
                map.setZoom(9);
                return
            }
            map.setZoom(6);
        }
        return () => clearTimeout(delayDebounceFn)
        
    }, [searchTerm, activeEst]);

    const onLoad = useCallback(function callback(map) {
        if (latUrl && lngUrl && typeUrl && stopQuery === false) {
            if (!latUrl && !lngUrl && !typeUrl) {
                establismentsGo();
            } 
            else {
                if (typeUrl === "atm") {
                    atmsGo();
                } 
                else {
                    establismentsGo();
                }
            }
            window.google.maps.event.addListenerOnce(map, 'tilesloaded', function(){ //full render map and markers
                const element = document.getElementById("map-box");
                element.scrollIntoView({block: "end"});
                map.setZoom(16);
                stopQuery = true;
                setMap(map)
                setTimeout(function (){
                    const mapLoading = refMap.current; // corresponding DOM node
                    mapLoading.className = "map-box d-flex f-wrap";
                }, 800);
            });
        } 
        else {
            if (activeMap === "est") {
                map.setZoom(9);
            } 
            else {
                map.setZoom(6);
            }
            setMap(map)
            setTimeout(function (){
                const mapLoading = refMap.current; // corresponding DOM node
                mapLoading.className = "map-box d-flex f-wrap";
            }, 800);
        }
    }, [])

    function toPoint(lat, lng, street) { //zoom to pin marker
        setCurSection(street);
        setTimeout(function () {
            map.setZoom(16);
            map.panTo({lat: parseFloat(lat), lng: parseFloat(lng)});
            setMap(map)
        }, 200) 
    }

    function createKey(e) {
        return e.lat + e.lng
    }

    function establismentsGo() {
        setActiveAtm('');
        setActiveEst('active');
        setSearchTerm('');
        activeMap = "est";
    }

    function atmsGo() {
        setActiveAtm('active');
        setActiveEst('');
        setSearchTerm('');
        activeMap = "atm";
    }

    useEffect(function () { //push items to array list
        locationListBranches.map((item, index) => {
            return (
                setLocationSearchingListEst(locationSearchingListEst => [...locationSearchingListEst, item])
            )
        })
        locationPlacesBranches.map((item, index) => {
            return (
                setLocationSearchingMapEst(locationSearchingMapEst => [...locationSearchingMapEst, item])
            )
        })
        setTimeout(function () {
            locationListCash.map((item, index) => {
                return (
                    setLocationSearchingListAtm(locationSearchingListAtm => [...locationSearchingListAtm, item])
                )
            })
            locationPlacesCash.map((item, index) => {
                return (
                    setLocationSearchingMapAtm(locationSearchingMapAtm => [...locationSearchingMapAtm, item])
                )
            })
        }, 600)
    }, []);

    return isLoaded ? (
        <section id='map-box' className={innerSection}>
            <div className="wrapper">
                <div className={innerContainer}>
                    <h1 className="">{params.block['en'].Title}</h1>
                    <div className='establishments-and-atms d-flex jc-center mt-20 mb-10'>
                        <span
                            className={"button disclaimer est " + activeEst}
                            onClick={() => {
                                const mapLoading = refMap.current; // corresponding DOM node
                                mapLoading.className = "map-box d-flex f-wrap loading";
                                establismentsGo();
                            }}
                        >
                            Placówki
                        </span>
                        <span
                            className={"button disclaimer atm " + activeAtm}
                            onClick={() => {
                                const mapLoading = refMap.current; // corresponding DOM node
                                mapLoading.className = "map-box d-flex f-wrap loading";
                                atmsGo();
                            }}
                        >
                            Bankomaty
                        </span>
                    </div>
                    <div ref={refMap} className={'map-box d-flex f-wrap loading'}>
                        <div className='establishments col-12 col-lg-4 col-md-12 col-sm-12'>
                            <div className='input-box ml-20 mt-20 mr-20'>
                                <Svg name="mapSearch" class="icon-input"/>
                                <input
                                    type="text"
                                    placeholder="Wyszukaj"
                                    className='pl-50'
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <span className='clear-search' onClick={() => setSearchTerm('')}></span>
                            </div>
                            <div className='location-items mt-20 mr-20'>
                                {activeEst === "active" && locationSearchingListEst.map((item, i) => {
                                    return (
                                        <div className={item.street === curSection ? 'pl-20 pt-10 pb-10 item active ' : 'pl-20 pt-10 pb-10 item'} key={"loc-item-est" + i}>
                                            <div className='name'>
                                                <span>{item.label}</span><br/>
                                            </div>
                                            <div className='about mb-10'>
                                                <span>{item.street}{", "}{item.postCode} {item.city}</span>
                                                <span
                                                    className="button disclaimer"
                                                    onClick={() => {
                                                        toPoint(item.lat, item.lng, item.street);
                                                    }}
                                                >
                                                    Pokaż miejsce
                                                </span>
                                            </div>
                                            <div className='phones-and-email'>
                                                {/*{item.phoneOffice && <span className="disclaimer mb-10 d-flex ai-center w-100">
                                                    <Svg name="mapOfficePhone" class="mr-10"/>
                                                    {item.phoneOffice}
                                                </span>}*/}
                                                {item.phone && 
                                                    item['phone'].map((items, i) => (
                                                        <span className="disclaimer mb-10 d-flex ai-center w-100">
                                                            <Svg name="mapPhone" class="mr-10"/>
                                                            {items}
                                                        </span>
                                                    ))
                                                }
                                                {item.email && <span className="disclaimer mb-10 d-flex ai-center w-100"> 
                                                    <Svg name="mapMail" class="mr-10"/>
                                                    {item.email}
                                                </span>}
                                            </div>
                                            {item.opendays && item.open && item.closed &&
                                                <>
                                                    <div className='about-hours'>
                                                        <span>Godziny otwarcia: {item.opendays}, {item.open} - {item.closed}</span><br/>
                                                    </div>
                                                    <div>
                                                        {item.isOpen && 
                                                            <span className='hours is-open'>{item.isOpen}</span>
                                                        }
                                                        {item.isClose && 
                                                            <span className='hours is-close'>{item.isClose}</span>
                                                        }
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    )
                                })}
                                {activeAtm === "active" && locationSearchingListAtm.map((item, i) => {
                                    return (
                                        <div className={item.street === curSection ? 'pl-20 pt-20 item active ' : 'pl-20 pt-20 item'} key={"loc-item-atm" + i}>
                                            <div className='name'>
                                                <span>{item.label}</span><br/>
                                            </div>
                                            <div className='about mb-10'>
                                                <span>{item.street} {item.postCode} {item.city}</span>
                                                <span
                                                    className="button disclaimer"
                                                    onClick={() => {
                                                        toPoint(item.lat, item.lng, item.street);
                                                    }}
                                                >
                                                    Pokaż miejsce
                                                </span>
                                            </div>
                                            <div className='phones-and-email'>
                                                {item.phoneOffice && <span className="disclaimer mb-10 d-flex ai-center w-100"> 
                                                    <Svg name="mapOfficePhone" class="mr-10"/>
                                                    {item.phoneOffice}
                                                </span>}
                                                {item.phone && <span className="disclaimer mb-10 d-flex ai-center w-100"> 
                                                    <Svg name="mapPhone" class="mr-10"/>
                                                    {item.phone}
                                                </span>}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className='map col-12 col-lg-8 col-md-12 col-sm-12'>
                            {activeEst === "active" &&
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={centerMapEst}
                                    zoom={9}
                                    onLoad={onLoad}
                                >
                                    <MarkerClusterer
                                        batchSizeIE={50}
                                        ignoreHidden="true"
                                        gridSize={80}
                                    >
                                        {(clusterer) =>
                                            locationSearchingMapEst.map((a, i) => (
                                                <Marker key={createKey(a) + "-est-" + i} noClustererRedraw="false" icon="/.templates/img/icons/svg/map-tick.svg" position={a} title={a.label} clusterer={clusterer} />
                                            ))
                                        }
                                    </MarkerClusterer>
                                </GoogleMap>
                            }
                            {activeAtm === "active" &&
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={centerMap}
                                    zoom={6}
                                    onLoad={onLoad}
                                >
                                    <MarkerClusterer
                                        batchSizeIE={50}
                                        ignoreHidden="true"
                                        gridSize={80}
                                    >
                                        {(clusterer) =>
                                            locationSearchingMapAtm.map((a, i) => (
                                                <Marker key={createKey(a) + "-atm-" + i} noClustererRedraw="false" icon="/.templates/img/icons/svg/map-tick.svg" position={a} title={a.label} clusterer={clusterer} />
                                            ))
                                        }
                                    </MarkerClusterer>
                                </GoogleMap>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    ) : <></>
}

export default MapBox;