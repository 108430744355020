import React, { useState, useEffect } from "react";
import { environment } from "./../../environment";
import TopBannerCarouselItem from "./TopBannerCarouselItem";

function TopBannerCarousel(params) {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('TopBannerCarousel')
        console.log(params)
    }

    let     autoTime = (params.block['en']['RotationTime']) ? parseFloat(params.block['en']['RotationTime']) : 5000,
            headerOne = params.block['en'].hasOwnProperty('HeaderOne') ? params.block['en']['HeaderOne'] : '';
    const   [active, setActive] = useState(0),
            [activeLegalNote, setActiveLegalNote] = useState('');

    useEffect(() => {
        if (activeLegalNote.length > 0) {
            if (activeLegalNote === 'active') {
                autoTime = 50000000;
            }
            if (activeLegalNote === 'closed') {
                autoTime = (params.block['en']['RotationTime']) ? parseFloat(params.block['en']['RotationTime']) : 5000;
            }
        }
        const timer = setTimeout(() => changesStageNext(), autoTime);

        return () => {
            clearTimeout(timer);
        };
    });

    function changesStageNext() {
        setActive(active + 1);
        if (active + 1 > params.block['en']['Slide'].length - 1) {
            setActive(0);
        }
    }
    function changesStageBack() {
        setActive(active - 1);
        if (active - 1 < 0) {
            setActive(params.block['en']['Slide'].length - 1);
        }
    }

    let numberSlider = params.block['en']['Slide'].length;
    let innerSection = 'top-banner-carousel';
    let innerContainer = 'container';
    return (
        <section aria-label="section" aria-roledescription="carousel" className={innerSection}>
  		    <div className="wrapper">
                <div className={innerContainer}>
                    <div className="w-100">
                        <div className="main-box d-flex">
                            {headerOne && 
                                <h1 className="header-one">
                                    {headerOne}
                                </h1>
                            }
                            <div className="d-flex w-100 item active">
                                <div className="item-main-box d-flex ai-center w-50">
                                    <div className="container text-box-inner">
                                        {params.block['en'].hasOwnProperty('Slide') && Object.keys(params.block['en']['Slide']).map((slider, i) => (
                                            <TopBannerCarouselItem
                                                key={'TopBannerCarouselItem-' + i}
                                                block={params.block['en']['Slide'][slider]}
                                                line={params.line}
                                                typeItem="content"
                                                classActive={active === i ? true : false}
                                                legalNote={setActiveLegalNote}
                                            />
                                            ))
                                        }
                                        {numberSlider > 1 &&
                                        <div className="nav-carousel d-flex ai-center mt-30">
                                            <span aria-label={"Previous slide"} className="back mr-20" onClick={() => changesStageBack()}></span>
                                            {params.block['en'].hasOwnProperty('Slide') && Object.keys(params.block['en']['Slide']).map((slider, i) => (
                                                    <span key={'stage-' + i} className={active === i ? "circle-stage active" : "circle-stage"}></span>   
                                                ))
                                            }
                                            <span aria-label={"Next slide"} className="next ml-20" onClick={() => changesStageNext()}></span>
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className="w-50 image">
                                {params.block['en'].hasOwnProperty('Slide') && Object.keys(params.block['en']['Slide']).map((slider, i) => (
                                        <TopBannerCarouselItem
                                            key={'TopBannerCarouselItemImg-' + i}
                                            block={params.block['en']['Slide'][slider]}
                                            line={params.line}
                                            typeItem="image"
                                            classActive={active === i ? true : false}
                                        />
                                    ))
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TopBannerCarousel;