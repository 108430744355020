import React from "react";
import { environment } from "../../environment";
import Svg from "../../components/Svg";
import Links from "../../components/Links";

const Documents = (params) => {
  if (
    window.origin.includes(environment.localhost) ||
    window.origin.includes(environment.devName)
  ) {
    console.log("Documents");
    console.log(params);
  }

  let colorBack = "";
  if (
    params.block["en"]["Background"] !== "" &&
    params.block["en"].hasOwnProperty("Background")
  ) {
    colorBack = "b-" + params.block["en"]["Background"];
  }
  let innerSection = "documents";
  let innerContainer = "container";
  if (
    params.block["en"]["InnerBackground"] !== "" &&
    params.block["en"].hasOwnProperty("InnerBackground")
  ) {
    if (params.block["en"]["InnerBackground"] === true) {
      innerContainer = innerContainer + " " + colorBack;
    } else {
      innerSection = innerSection + " " + colorBack;
    }
  }

  const DocumentItem = ({ item }) => {
    let link = '/';
    let name = 'Dokument';
    if (item.hasOwnProperty('Link')) {
      if (item['Link'] !== '') link = item['Link']
    }
    if (item.hasOwnProperty('LinkName')) {
      if (item['LinkName'] !== '') name = item['LinkName']
    }
    return (
      <li className="list-element d-flex f-row ai-center ">
        <Svg name="PDFicon" className="" />
        <Links url={link} name={name} class="documents-url" line={params.line} target='true'/>
      </li>
    );
  };

  return (
    <section className={`${innerSection}`}>
      <div className="wrapper">
        <div className={`container d-flex jc-center f-column ${innerContainer}`}>
          {params.block["en"]["Title"] && (
            <h2 className="mb-20">{params.block["en"]["Title"]}</h2>
          )}
          <ul>
            {params.block["en"].hasOwnProperty('Item') && params.block["en"]['Item'].map((item, index) => {
              return <DocumentItem item={item} key={index} />;
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Documents;
