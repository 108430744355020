import React from 'react';
import { environment, project } from './../environment';

function Image(params) {

    let online = project.online;
    let image = '';

    if (params.src !== '') {
        if (params.src.hasOwnProperty('link')) {
            image = params.src.link;
        } else if (params.src.hasOwnProperty('path')) {
            image = params.src.path;
        } else {
            image = params.src;
        }
    }

    if (image.includes(environment.siteSites)) {
        image = image.split(environment.siteSites);
        image = image[1];
    }

    if (window.location.origin.includes(environment.localhost) && !(image.includes('http://') || image.includes('https://'))) {
        image = environment.siteUrlDev + image;
    }
    else if (window.location.origin.includes(environment.cmsName) && !(image.includes('http://') || image.includes('https://'))) {
        image = window.location.origin + image
        /*let urlSite = window.location.origin;
        urlSite = urlSite.replace('cms-', '');
        image = urlSite+image; (not work for prod cms)*/
    }
    if (online) {
        if (params.width !== '' && params.width && !image.includes('.svg') && !image.includes('.templates')) {
            image = image + '_width=' + params.width;
        }
    }

    return (
        <img loading="lazy" className={params.class} src={image} alt={params.alt} />
    )
}

export default Image;