import React, { useState, useEffect } from "react";
import { environment } from "../../environment";
import ReactCountryFlag from "react-country-flag";
import axios from "axios";
import Svg from "../../components/Svg";

const ExchangeRate = (params) => {
  const [currency, setCurrency] = useState([]),
    [currentPage, setCurrentPage] = useState(1),
    [postsPerPage, setPostPerPage] = useState(4),
    [active, setActive] = useState(0),
    [value, setValue] = useState(""),
    [branchesData, setBranchesData] = useState([]);

  if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
    console.log("ExchangeRate");
    console.log(params);
  }

  let colorBack = "";
  if (
    params.block["en"]["Background"] !== "" &&
    params.block["en"].hasOwnProperty("Background")
  ) {
    colorBack = "b-" + params.block["en"]["Background"];
  }
  let innerSection = "exchange-rate";
  let innerContainer = "container";
  if (
    params.block["en"]["InnerBackground"] !== "" &&
    params.block["en"].hasOwnProperty("InnerBackground")
  ) {
    if (params.block["en"]["InnerBackground"] === true) {
      innerContainer = innerContainer + " " + colorBack;
    } else {
      innerSection = innerSection + " " + colorBack;
    }
  }

  // fetch Branches and ATM's data
  let url = null;
  if (window.location.origin.includes(environment.cmsName)) {
    url = window.location.origin;
  }
  else if (window.location.origin.includes(environment.localhost)) {
      url = environment.siteUrlDev;
  }
  else {
      url = window.location.origin;
  }
  useEffect(() => {
    const fetchBranches = async () => {
      let endpoints = [
        url+environment.branches,
        url+environment.cashDispensers,
      ];
      axios
        .all(endpoints.map((endpoint) => axios.get(endpoint)))
        .then((data) => setBranchesData([...data[0].data, ...data[1].data]));
    };

    fetchBranches();
  }, []);

  // value from input handler

  const onChangeValue = (event) => {
    setValue(event.target.value);
  };

  // filtering mechanism

  const onSearch = (searchTerm) => {
    setValue(searchTerm);
  };

  //axios fetch Currencies from API inside useEffect

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        url+environment.currencies
      );

      const arrayOfObjects = Object.keys(response.data.values).map((key) =>
        Object.assign(response.data.values[key], { symbol: key })
      );

      const indexDKK = arrayOfObjects.findIndex((e) => e.symbol === "DKK");
      arrayOfObjects.unshift(arrayOfObjects.splice(indexDKK, 1)[0]);

      const indexSEK = arrayOfObjects.findIndex((e) => e.symbol === "SEK");
      arrayOfObjects.unshift(arrayOfObjects.splice(indexSEK, 1)[0]);

      const indexNOK = arrayOfObjects.findIndex((e) => e.symbol === "NOK");
      arrayOfObjects.unshift(arrayOfObjects.splice(indexNOK, 1)[0]);

      const indexCHF = arrayOfObjects.findIndex((e) => e.symbol === "CHF");
      arrayOfObjects.unshift(arrayOfObjects.splice(indexCHF, 1)[0]);

      const indexGBP = arrayOfObjects.findIndex((e) => e.symbol === "GBP");
      arrayOfObjects.unshift(arrayOfObjects.splice(indexGBP, 1)[0]);

      const indexUSD = arrayOfObjects.findIndex((e) => e.symbol === "USD");
      arrayOfObjects.unshift(arrayOfObjects.splice(indexUSD, 1)[0]);

      const indexEUR = arrayOfObjects.findIndex((e) => e.symbol === "EUR");
      arrayOfObjects.unshift(arrayOfObjects.splice(indexEUR, 1)[0]);

      setCurrency(arrayOfObjects);
    };

    fetchData();
  }, []);

  /*  Get current posts */

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = currency && currency.slice(indexOfFirstPost, indexOfLastPost);

  // Get number of Pages

  const pageNumbers = [];
  const totalPosts = currency && currency.length;

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  // Change page next

  const paginateForward = () => {
    setActive(active + 1);
    if (active + 1 > pageNumbers.length - 1) {
      setActive(0);
    }

    setCurrentPage((prevState) => {
      if (prevState >= Math.ceil(totalPosts / postsPerPage)) {
        return prevState - (Math.ceil(totalPosts / postsPerPage) - 1);
      } else {
        return prevState + 1;
      }
    });
  };

  // Change page back

  const paginateBackward = () => {
    setActive(active - 1);
    if (active - 1 < 0) {
      setActive(pageNumbers.length - 1);
    }
    setCurrentPage((prevState) => {
      if (prevState === 1) {
        return prevState + (Math.ceil(totalPosts / postsPerPage) - 1);
      } else {
        return prevState - 1;
      }
    });
  };

  return (
    <section className={`exchange-rate${innerSection}`}>
      <div className="wrapper">
        <div className={`container-wide d-flex jc-center ${innerContainer}`}>
          <div className="main-box d-flex g-15 w-100">
            <div className="exchange-rate-box container">
              <div className="exchange-rate-box-title ">
                {params.block["en"]["Title"] && (
                  <h2>{params.block["en"]["Title"]}</h2>
                )}

                <div className="nav-carousel d-flex ai-center ">
                  <div onClick={paginateBackward} className="mr-15">
                    <Svg name="ArrowLeft" />
                  </div>

                  {pageNumbers.map((number, i) => (
                    <span
                      key={"stage-" + i}
                      className={
                        active === i ? "circle-stager-active" : "circle-stager"
                      }
                    >
                    </span>
                  ))}
                  <div onClick={paginateForward} className="arrow-right ml-15">
                    <Svg name="ArrowLeft" />
                  </div>
                </div>
              </div>
              <div>
                <table className="table">
                  <tbody valign="baseline" className="tbody">
                    <tr align="center">
                      <th>Waluta</th>
                      <th>Kupno</th>
                      <th>Sprzedaż</th>
                    </tr>
                    {currentPosts.map((item) => (
                      <tr className="table-row" key={item.key} valign="middle">
                        <td className="currency-box">
                          <ReactCountryFlag
                            style={{
                              fontSize: "2.5em",
                              lineHeight: "2.5em",
                              borderRadius: "10px",
                            }}
                            countryCode={item.symbol.slice(0, 2)}
                            svg
                            className="mr-10"
                          />
                          <p className="currency-item"> 1 {item.symbol}</p>
                        </td>
                        <td valign="middle" className="currency-buy-box">
                          <div className="currency-box-arrow">
                            {item.increase === "1" ? (
                              <Svg
                                name="ArrowUp"
                                class="currency-box-arrow-up"
                              />
                            ) : (
                              <Svg
                                name="ArrowDown"
                                class="currency-box-arrow-up"
                              />
                            )}
                            {item.buy}
                            <span className="pln"> PLN</span>
                          </div>
                        </td>
                        <td className="currency-sell-box">
                          {item.sell} <span className="pln-second"> PLN</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="branches-and-atms-box">
              {params.block["en"]["SecondTitle"] && (
                <h2 className="exchange-title">
                  {params.block["en"]["SecondTitle"]}
                </h2>
              )}
              <div className="grid-container">
                <div className="big-icon ml-30">
                  <Svg name="SearchWithBank" />
                </div>
                <div>
                  <p className="branches-para mb-20">
                    Znajdź placówki banku lub bankomaty z darmowymi wypłatami w
                    Polsce.
                  </p>
                  <div className="search-container">
                    <div className="search-inner">
                      <form className="search-form">
                        <label className="label">
                          <input
                            className="input"
                            type="text"
                            value={value}
                            onChange={onChangeValue}
                            placeholder="wpisz lokalizację"
                          />
                        </label>
                      </form>
                    </div>
                    <div className="dropdown">
                      {branchesData
                        .filter((item) => {
                          const searchTerm = value.toLowerCase();
                          const fullName = item.city_post.toLowerCase();

                          return (
                            searchTerm &&
                            fullName.includes(searchTerm) 
                          );
                        })
                        .slice(0, 5)
                        .map((item) => {
                          if ("machines" in item) {
                            return (
                              <a
                                href={`/kontakt/?lat=${item.lat}&lng=${item.long}&type=est`}
                                className="filter-link"
                              >
                                <div
                                  onClick={() => onSearch(item.full_name)}
                                  className="dropdown-row"
                                  key={item.city_post}
                                >
                                  {item.branch_name}
                                  {", "}
                                  {item.street}
                                  {", "}
                                  {item.city_post}
                                </div>
                              </a>
                            );
                          } else {
                            return (
                              <a
                                href={`/kontakt/?lat=${item.lat}&lng=${item.long}&type=atm`}
                                className="filter-link"
                              >
                                <div
                                  onClick={() => onSearch(item.full_name)}
                                  className="dropdown-row"
                                  key={item.city_post}
                                >
                                  Bankomat
                                  {", "}
                                  {item.street}
                                  {", "}
                                  {item.city_post}
                                </div>
                              </a>
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExchangeRate;