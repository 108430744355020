import React from 'react';
import Select from 'react-select';

const ReactSelect = React.forwardRef((params, ref) => {

    return (
        <Select
            isSearchable={params.isSearchable}
            ref={ref}
            name={params.name}
            id={params.id}
            className={params.className}
            options={params.options && params.options}
            defaultValue={params.defaultValue && params.defaultValue}
            onChange={params.onChange}
            classNames={{
                container: (params) => {

                    let focused = params.isFocused ? ' focused' : '',
                        valued = params.selectProps.value.value !== '' ? '' : ' empty';

                    return `react-select${focused}${valued}`;
                },
                control: (params) => {

                    let required = params.selectProps.className;

                    return `control${required && required.search('required') >= 0 ? ' required' : ''}`
                },
                indicatorSeparator: () => 'separator',
                valueContainer: () => 'input',
                singleValue: (state) => {

                    return `label${state.data.value !== '' ? '' : ' empty'}`
                },
                dropdownIndicator: () => 'indicator',
                menu: () => 'listbox',
                option: (state) => {

                    let empty = state.data.value === '' ? ' empty' : '',
                        focused = state.isFocused ? (state.isDisabled ? '' : ' focused') : '',
                        selected = state.isSelected ? ' selected' : '',
                        disabled = state.isDisabled ? ' disabled' : '';

                    return `option${empty}${focused}${selected}${disabled}`;
                }
            }}
            styles={{
                option: (styles, state) => ({

                    color: state.isSelected ? 'var(--white)' : (state.isDisabled ? 'var(--color5)' : 'var(--main)'),
                    fontWeight: state.isSelected ? 'bold' : 'normal',
                    padding: '10px'
                })
            }}
        />
    )
});
export default ReactSelect;