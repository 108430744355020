import React from "react";

function Loaders(params) {
    if (params && params.type === 'dots') {
        return (
            <div className="lds-ellipsis">
                <div className={params.color}></div>
                <div className={params.color}></div>
                <div className={params.color}></div>
                <div className={params.color}></div>
            </div>
        )
    }
    else if (params && params.mode && params.mode === "true") {
        return (
            <div className={`loader-circle `+params.color}>
                <div className="circle-box">
                    <span className="loadspan">Ładowanie...</span>
                    <svg className="circle-round" xmlns="http://www.w3.org/2000/svg" viewBox="25 25 50 50" width="60px" height="60px" data-component="LoaderIcon">
                        <circle fill="#ffffff" stroke="#201c17" opacity="0.1" cx="50" cy="50" r="20" className="circle-back"></circle>
                        <circle fill="none" cx="50" cy="50" r="20" className="circle-front"></circle>
                    </svg>
                </div>
            </div>
        )
    }
    else {//params.type === 'circle'
        return (
            <div id="loader" className={params.color}>
                <div className="circle-box">
                    <span className="loadspan">Ładowanie...</span>
                    <svg className="circle-round" xmlns="http://www.w3.org/2000/svg" viewBox="25 25 50 50" width="60px" height="60px" data-component="LoaderIcon">
                        <circle fill="#ffffff" stroke="#201c17" opacity="0.1" cx="50" cy="50" r="20" className="circle-back"></circle>
                        <circle fill="none" cx="50" cy="50" r="20" className="circle-front"></circle>
                    </svg>
                </div>
            </div>
        )
    }
};
export default Loaders;