import React from "react";
import { createRoot } from "react-dom/client";
import { environment } from "./environment";
import TopBannerCarousel from "./portlets/topBannerCarousel/TopBannerCarousel";
import TextBox from "./portlets/textBox/TextBox";
import TextImage from "./portlets/textImage/TextImage";
import VideoBox from "./portlets/video-box/VideoBox";
import ListBox from "./portlets/listBox/ListBox";
import NewsTeaser from "./portlets/news/NewsTeaser";
import NewsDetails from "./portlets/news/NewsDetails";
import Galleries from "./portlets/galleries/Galleries";
import SearchBox from "./portlets/searchBox/SearchBox";
import Accordion from "./portlets/accordion/Accordion";
import Infobox from "./portlets/infobox/Infobox";
import ManagementBox from "./portlets/managementBox/ManagementBox";
import Benefits from "./portlets/benefits/Benefits";
import ExchangeRate from "./portlets/exchangeRate/ExchangeRate";
import TopBanner from "./portlets/topBanner/TopBanner";
import RecruitmentForm from "./portlets/recruitmentForm/RecruitmentForm";
import Button from "./portlets/button/Button";
import Documents from "./portlets/documents/Documents";
import MapBox from "./portlets/mapBox/MapBox";
import ContactForm from "./portlets/contactForm/ContactForm";
import Charts from "./portlets/charts/Charts";
import Flexible from "./portlets/flexible/Flexible";
import CalcIframe from "./portlets/calcIframe/CalcIframe";
import Infobar from "./portlets/infobar/Infobar";

const Components = {
    "top-banner-carousel": TopBannerCarousel,
    "text-box": TextBox,
    "text-image": TextImage,
    "video-box": VideoBox,
    "news-details": NewsDetails,
    "news-details-teaser": NewsTeaser,
    "galleries": Galleries,
    "search-box": SearchBox,
    "accordion": Accordion,
    "infobox": Infobox,
    "management-box": ManagementBox,
    "benefits": Benefits,
    "exchange-rate" : ExchangeRate,
    "top-banner" : TopBanner,
    "recruitment-form" : RecruitmentForm,
    "button" : Button,
    "documents" : Documents,   
    "map-box": MapBox,
    'contact-form': ContactForm,
    "charts": Charts,
    "flexible": Flexible,
    "calc-iframe": CalcIframe,
    "infobar": Infobar,
};

function Portlets(line, block, pageData, index, locale) {

    function CreateComp(portlet, name, index, min) {
        const Portlet = Components[name];
        return <Portlet block={portlet} key={index} line={line} min={min} />;
    }

    if (line === 'online') {
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
            console.log('Portlets list www')
            console.log(block)
        }
        if (typeof pageData !== "undefined" && !block.hasOwnProperty('exception')) {
            if (block.hasOwnProperty('listInfo')) {
                if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
                    console.log('potlet list box');
                }
                //must be wrapped into LIST portlet
                let key = pageData.split("/").pop();
                key = key.substring(0, key.length - 4);
                return <ListBox block={block} line={line} key={key + '_' + index} />
            }
            else {
                if (block.hasOwnProperty('attributes') && block.attributes.hasOwnProperty('type')) {
                    let type = block.attributes.type
                    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
                        console.log('portlet type - ' + type)
                    }
                    if (Components.hasOwnProperty(type)) {
                        if (type !== 'infobar') {
                            let key = pageData.split("/").pop();
                            key = key.substring(0, key.length - 4);
                            return React.createElement(Components[type], {
                                key: key + '_' + index,
                                block: block,
                                line: line
                            });
                        }
                        else {
                            let key = pageData.split("/").pop();
                                key = key.substring(0, key.length - 4);
                            let portletId = document.getElementById('infobar');
                            if (portletId) {
                                let box = createRoot(portletId);
                                box.render(
                                    CreateComp(block, type, key + "_" + index)
                                )
                            }
                        }
                    }
                }
                else { console.error('portlet type - is undefined') }
            }
        }
    }
    if (line === 'offline') {
        let listComp = []
        block.map((cont, i) => {
            //const type = cont.type; //get container info
            //const id = cont.name; //may be usefull later
            cont.elements.map((item, ii) => {
                const uuid = item.formatterKey;
                const path = item.path;
                listComp.push({ uuid, path })
                return null;
            })
            return null;
        })
        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
            console.log('Portlets list cms')
            console.log(pageData.linkedContents)
        }

        listComp.map((elem, i) => {
            let portlet = pageData.linkedContents[elem.path]
            if (!portlet.hasOwnProperty('exception')) {
                if (portlet.hasOwnProperty('list')) {
                    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
                        console.log('potlet list box');
                    }
                    portlet.list.map((teaser, i) => {
                        let type = teaser.attributes.type;
                        if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
                            console.log('portlet type - ' + type)
                        }
                        if (Components.hasOwnProperty(type)) {
                            let id = teaser.path.split("/").pop();
                            id = id.substring(0, id.length - 4);
                            let teaserId = document.getElementById(id);
                            let min = portlet.hasOwnProperty('Minified') ? portlet['Minified'] : false;
                            if (teaserId) {
                                let box = createRoot(teaserId);
                                box.render(
                                    CreateComp(teaser, type + '-teaser', id + "_" + i, min)
                                )
                            }
                        }
                        return null;
                    })
                }
                else {
                    if (portlet.hasOwnProperty('attributes')) {
                        if (portlet.attributes.hasOwnProperty('type')) {
                            let type = portlet.attributes.type;
                            if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
                                console.log('portlet type - ' + type)
                            }
                            if (Components.hasOwnProperty(type)) {
                                let id = elem.path.split("/").pop();
                                id = id.substring(0, id.length - 4);
                                let portletId = document.getElementById(id);
                                if (portletId) {
                                    let box = createRoot(portletId);
                                    box.render(
                                        CreateComp(portlet, type, id + "_" + i)
                                    )
                                }
                            }
                        }
                    }
                }
            }
            return null;
        });
    }
};
export default Portlets;