import React from "react"
import { environment } from "./../../environment"
import TextLinks from "../../components/TextLinks"
const Flexible = (params) => {
  if (
    window.origin.includes(environment.localhost) ||
    window.origin.includes(environment.devName)
  ) {
    console.log("Flexible")
    console.log(params)
  }

  let colorBack = ""
  if (
    params.block["en"]["Background"] !== "" &&
    params.block["en"].hasOwnProperty("Background")
  ) {
    colorBack = "b-" + params.block["en"]["Background"]
  }
  let innerSection = "flexible"
  let innerContainer = "container d-flex f-column jc-center"
  if (
    params.block["en"]["InnerBackground"] !== "" &&
    params.block["en"].hasOwnProperty("InnerBackground")
  ) {
    if (params.block["en"]["InnerBackground"] === true) {
      innerContainer = innerContainer + " " + colorBack
    } else {
      innerSection = innerSection + " " + colorBack
    }
  }

  return (
    <section className={innerSection}>
      <div className="wrapper">
        <div className={innerContainer}>
          {params.block["en"]["Title"] && (
            <h2 className="mb-20">{params.block["en"]["Title"]}</h2>
          )}
          {params.block['en']['Code'] && 
            <div class="code-box" dangerouslySetInnerHTML={{__html: params.block["en"]["Code"]}}>
              
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default Flexible
