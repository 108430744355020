import React, { useRef, useState, useEffect } from 'react';
import ReactRecaptcha3 from 'react-google-recaptcha3';
import axios from 'axios';
import { environment } from './../../environment';
import PhoneInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';
import ReactSelect from '../../components/Select';
import FileUploader from '../../components/FileUploader';
import log from '../../log';

const origin = window.origin;
let formValid = false;  //if all required fields are filled in correctly

function ContactForm(params) {

    const [isLoading, setLoading] = useState(true); //indicator of successfull config upload

    let colorBack = '',
        innerSection = 'contact-form',
        innerContainer = 'container',
        agreeText = '',
        additionalText = '';

    if (params.block['en']['Background'] !== '' && params.block['en'].hasOwnProperty('Background')) {
        colorBack = 'b-' + params.block['en']['Background'];
    }
    if (params.block['en']['InnerBackground'] !== '' && params.block['en'].hasOwnProperty('InnerBackground')) {
        if (params.block['en']['InnerBackground'] === true) {
            innerContainer = innerContainer + ' ' + colorBack;
        }
        else {
            innerSection = innerSection + ' ' + colorBack;
        }
    }
    if (params.block['en']['AgreeText'] !== '' && params.block['en'].hasOwnProperty('AgreeText')) {
        agreeText = params.block['en']['AgreeText'];
    }
    if (params.block['en']['AdditionalText'] !== '' && params.block['en'].hasOwnProperty('AdditionalText')) {
        additionalText = params.block['en']['AdditionalText'];
    }
    let captchaKey = '',
        dataToSend = {};

    if (origin.includes(environment.devName) || origin.includes(environment.localhost)) {
        captchaKey = environment.devreCaptchaKey;
    }
    else if (origin.includes(environment.testName)) {
        captchaKey = environment.testreCaptchaKey;
    }
    else if (origin.includes(environment.prodName)) {
        captchaKey = environment.prodreCaptchaKey
    }
    //form fields variables
    const [client, setClient] = useState(null),
        [name, setName] = useState(''),
        [companyName, setCompanyName] = useState(''),
        [city, setCity] = useState(''),
        [valueNumber, setValueNumber] = useState(''),
        [email, setEmail] = useState(''),
        [department, setDepartment] = useState(''),
        [subject, setSubject] = useState(''),
        [message, setMessage] = useState(''),
        [agreeRodo, setAgreeRodo] = useState(false);
    //form status variables
    const [submit, setSubmit] = useState(false),
        [pending, setPending] = useState(false),
        [errorCompany, setErrorCompany] = useState(false),
        [errorMail, setErrorMail] = useState(0),    //no error
        [errorPhone, setErrorPhone] = useState(0),  //no error
        [errorCity, setErrorCity] = useState(0),    //no error
        [errorDepartment, setErrorDepartment] = useState(false),
        [errorSubject, setErrorSubject] = useState(false),
        [errorMessageInput, setErrorMessageInput] = useState(false),
        [rodoStatus, setRodoStatus] = useState(true),
        [formError, setFormError] = useState(false),    //status change after sending the form
        [formSuccess, setFormSuccess] = useState(false);    //status change after sending the form

    //config variables (with default values)
    const [submitMessage, setSubmitMessage] = useState("Formularz został wysłany. Dziękujemy."),
        [errorMessage, setErrorMessage] = useState(''),
        [wrongFile, setWrongFile] = useState(false),
        [submitmessageVisible, setSubmitMessageVisible] = useState(false),
        [errorMessageVisible, setErrorMessageVisible] = useState(false),
        [allowedClientTypes, setAllowedClientTypes] = useState([{}]),
        [allowedDepartments, setAllowedDepartments] = useState([
            { value: '', label: '' },
        ]),
        [allowedSubjects, setAllowedSubjects] = useState([
            { value: '', label: '' },
        ]),
        [allowedMimeTypes, setAllowedMimeTypes] = useState([]),
        [maxAllowedSize, setMaxAllowedSize] = useState(null), //in bytes
        maxFilesNumber = 3;   //hardcoded, value not in config

    const getConfigData = () => {
        let url = null;
        if (window.location.origin.includes(environment.cmsName)) {
            url = window.location.origin;
        }
        else if (window.location.origin.includes(environment.localhost)) {
            url = environment.siteUrlDev;
        }
        else {
            url = window.location.origin;
        }
        axios.get(url + environment.formApiContact).then(response => {
            const res = response.data;
            const getDataForSelect = (obj) => {
                let arr = [];
                for (var i in obj) {
                    arr.push({ 'value': i, 'label': obj[i] });
                }
                return arr;
            }
            setAllowedClientTypes([...getDataForSelect(res.allowedClientTypes)]);
            setAllowedDepartments([...allowedDepartments, ...getDataForSelect(res.allowedDepartments)]);
            setAllowedSubjects([...allowedSubjects, ...getDataForSelect(res.allowedSubjects)]);
            setAllowedMimeTypes([...res.allowedMimeTypes]);
            setMaxAllowedSize(res.maxAllowedSize);
            setLoading(false);
        });
    }
    //regular expressions (used in field validation)
    const reletters = /^(?![\s.]+$)[a-zA-Z-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s.]*$/;//not numbers and not special characters but space
    const rephone = /^\+?[0-9]{11,12}$/;
    const remail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    //references for input and select HTML elements in form
    const selClient = React.createRef(),
        inpName = useRef(null),
        inpCompany = useRef(null),
        inpEmail = useRef(null),
        inpPhone = useRef(null),
        inpCity = useRef(null),
        selDepartment = React.createRef(),
        selSubject = React.createRef(),
        inpMessage = useRef(null),
        inpRodo = useRef(null);

    //variables and functions to control FileUploader component
    const [files, setFiles] = useState([]);
    const [clear, setClear] = useState(false);

    //this hook runs only once at the first render (as componentDidMount function)
    useEffect(() => {
        log('Contact Form', 'f');
        log(params, 'f');
        getConfigData();    //fetching config data
        setClear(true); //remove attachments
        ReactRecaptcha3.init(captchaKey).then((status) => { });
    }, []);

    useEffect(() => {
        if (errorMessage.length > 0) {
            setErrorMessageVisible(true)
            if (wrongFile && wrongFile === "Other") {
                setFiles([]);
            }
        }
        const timer = setTimeout(() => setErrorMessageVisible(false), 6000);
    
        return () => {
            clearTimeout(timer);
        };
    }, [errorMessage]);
      
    useEffect(() => {
        const timer = setTimeout(() => setSubmitMessageVisible(false), 5000);
    
        return () => {
            clearTimeout(timer);
        };
    }, [submitmessageVisible]);

    //hook runs everytime when formSuccess or formError variables are changing
    useEffect(() => {

        if (formSuccess || formError) {
            //log('[after sending] formSuccess: ' + formSuccess + ' formError: ' + formError);
            afterSending(formSuccess, formError);
        }
    }, [formSuccess, formError]);

    //this hook is executed when form errors and field values changing 
    useEffect(() => {

        if ((formSuccess || formError) && (name || companyName || email || valueNumber || city || department || subject || message || rodoStatus || Object.keys(files).length)) {
            log('[data] name: ' + name + ' companyName: ' + companyName + ' emaiil: ' + email + ' phone: ' + valueNumber + ' city: ' + city + ' department: ' + department + ' subject: ' + subject + ' message: ' + message + ' rodoStatus: ' + rodoStatus + ' files: ' + Object.keys(files).length);
            setFormError(false);
            setFormSuccess(false);
        }
    }, [name, companyName, email, valueNumber, city, department, subject, message, rodoStatus, files]);

    //function to control animated placeholder behaviour and 'error' CSS class on elements
    const setActivity = (elm, active, valid) => {

        if (valid === undefined) {
            valid = active;
        }
        //log(elm + ' ' + ' name: ' + name + ' ' + active);
        if (!valid) {
            elm.parentElement.classList.add('error');
        }
        else {
            elm.parentElement.classList.remove('error');
        }
        let p = elm.nextElementSibling;

        if (p && p.classList.contains('placeholder')) {
            if (active) {
                p.classList.add('active');
            }
            else {
                p.classList.remove('active');
            }
        }
        return valid;
    };

    //below are functions to fields validation
    const changeName = name => {
        let activity = false;
        setName(name);
        if (name === '' || reletters.test(name)) {
            if (name !== '') {
                activity = true;
            }
        }
        return setActivity(inpName.current, activity, true);    //name isn't required so error on this field is always set to true (no error)
    };

    const changeClientType = client => {
        let c = inpCompany.current, f = c.parentNode;
        if (client === 'firmowy') { //for business client have to reveal field for name of the company
            c.disabled = false;
            f.classList.remove('hide');
        }
        else {
            c.disabled = true;
            f.classList.add('hide');
        }
        setClient(client);
        setActivity(document.getElementById('client'), client ? true : false, true);
    };

    const changeCompany = (companyName, valid) => {
        let activity = false;
        if (valid === undefined) {
            valid = false;
        }
        setCompanyName(companyName);
        if (companyName !== '') {
            activity = true;

            if (reletters.test(companyName)) {
                valid = true;
            }
        }
        setErrorCompany(!valid);
        return setActivity(inpCompany.current, activity, valid);
    };

    const validateEmail = (email, phone, mailValid) => {

        let activity = false,
            phoneValid = false,
            phoneError = 0,
            mailError = 0;

        if (mailValid === undefined) {
            mailValid = false;
        }
        if (phone === undefined) {
            phone = valueNumber;
        }
        setEmail(email);

        if (email) {
            activity = true;

            if (remail.test(email)) {
                mailError = 0;
                mailValid = true;

                if (!phone) {
                    phoneValid = true;  //if email address is set than phone number isn't required (no error)
                    phoneError = 0;
                }
            }
            else {
                setEmail('wrong');
                mailError = 2;
                phoneError = 1;
            }
            if (rephone.test(phone)) {
                phoneValid = true;
                phoneError = 0;
            }
        }
        else {
            phoneError = 1;
            mailError = 1;

            if (rephone.test(phone)) {
                phoneValid = true;
                phoneError = 0;
                mailValid = true;
                mailError = 0;
            }
            if (mailValid && !phone) {
                mailError = 0;
                phoneValid = true;
                phoneError = 0;
            }
        }
        setErrorMail(mailError);
        setErrorPhone(phoneError);
        setActivity(inpPhone.current, (valueNumber ? true : false), phoneValid);
        return setActivity(inpEmail.current, activity, mailValid);
    };

    const changePhone = (phone, emailAddress, phoneValid) => {

        let activity = false,
            emailValid = false,
            emailError = 0,
            phoneError = 0;

        if (phoneValid === undefined) {
            phoneValid = false;
        }
        if (emailAddress === undefined) {
            emailAddress = email;
        }
        setValueNumber(phone || '');

        if (phone) {
            activity = true;

            if (rephone.test(phone)) {
                phoneValid = true;

                if (!emailAddress) {
                    emailValid = true;  //if phone number is set than email address isn't required (no error)
                    emailError = 0;
                }
            }
            else {
                phoneError = 2;   //phone number is invalid
                emailError = 1;
            }
            if (remail.test(emailAddress)) {
                emailValid = true;  //if phone number is set than email address isn't required (no error)
                emailError = 0;
            }
        }
        else {
            phoneError = 1;   //no phone nor email
            emailError = 1;

            if (remail.test(emailAddress)) {
                emailError = 0;
                emailValid = true;
                phoneError = 0;
                phoneValid = true;
            }
            if (phoneValid && !emailAddress) {
                emailValid = true;
                emailError = 0;
                phoneError = 0;
            }
        }
        setErrorPhone(phoneError);
        setErrorMail(emailError);
        setActivity(inpEmail.current, (emailAddress ? true : false), emailValid);
        return setActivity(inpPhone.current, activity, phoneValid);
    };

    const changeCity = (city, valid) => {

        let activity = false,
            error = 0;

        if (valid === undefined) {
            valid = false;
        }
        setCity(city);

        if (city !== '') {
            activity = true;

            if (reletters.test(city)) {
                if (city.length < 3) {
                    error = 3;
                    valid = false;
                }
                else {
                    error = 0;
                    valid = true;
                }
            }
            else {
                error = 2;
            }
        }
        else {
            error = valid ? 0 : 1;
        }
        setErrorCity(error);
        return setActivity(inpCity.current, activity, valid);
    };

    const changeDepartment = (department, valid) => {

        if (!department) {
            department = '';
        }
        setDepartment(department);

        if (department !== '') {
            valid = true;
        }
        setErrorDepartment(!valid);
        return setActivity(document.getElementById('department'), (department !== '' ? true : false), valid);
    };

    const changeSubject = (subject, valid) => {

        if (!subject) {
            subject = '';
        }
        setSubject(subject);

        if (subject !== '') {
            valid = true;
        }
        setErrorSubject(!valid);
        return setActivity(document.getElementById('subject'), (subject !== '' ? true : false), valid);
    };

    const changeMessage = (message, valid) => {

        let activity = false;
        setMessage(message);

        if (message !== '') {
            activity = true;
            valid = true;
        }
        setErrorMessageInput(!valid);
        return setActivity(inpMessage.current, activity, valid);
    };

    const statusHandlerRodo = (agree, valid) => {

        if (agree === undefined) {
            agree = !agreeRodo;
        }
        if (valid === undefined) {
            valid = agree;
        }
        setAgreeRodo(agree);

        if (agree) {
            valid = true;
        }
        setRodoStatus(agree);
        return setActivity(inpRodo.current, agree, valid);
    };
    //fields validation end

    const filesToSend = (uploadedFiles) => {

        setFiles(uploadedFiles);
        setClear(false);
    };
    //end of control FileUpdater section

    const handleClickScroll = () => {
        setTimeout(function () {
            const element = document.getElementById('form-scroll');
            if (element) {
                element.scrollIntoView({ block: 'start' });
            }
        }, 200)
    };

    //function for form validation, invoked on 'send message' submit button click (and immediately after sending)
    const StatusHandler = (submit, success, errors) => {

        log('[StatusHandler] submit: ' + submit + ' success: ' + success + ' errors: ' + errors);
        setSubmit(submit);    //setting button after click state
        setPending(submit || success !== undefined || errors !== undefined);

        let validFields = [];

        validFields.push(changeName(submit ? name : ''));

        if (!client || client === 'indywidualny') {  //select element did not change and client has default value (null)
            setClient(allowedClientTypes[0].value);
        }
        if (client === 'firmowy') {
            validFields.push(changeCompany(submit ? companyName : '', !submit));
        }
        validFields.push(validateEmail(submit ? email : '', submit ? valueNumber : '', !submit));
        validFields.push(changePhone(submit ? valueNumber : '', submit ? email : '', !submit));
        validFields.push(changeCity(submit ? city : '', !submit));
        validFields.push(changeDepartment(submit ? department : '', !submit));
        validFields.push(changeSubject(submit ? subject : '', !submit));
        validFields.push(changeMessage(submit ? message : '', !submit));
        validFields.push(statusHandlerRodo(submit ? agreeRodo : false, !submit));

        if (submit && !success && !errors) {
            setFormError(false);
            setFormSuccess(false);
            formValid = true;

            for (let i = 0; i < validFields.length; i++) {
                if (!validFields[i]) {
                    formValid = false;
                    setSubmit(false);
                    break;
                }
            }
        }
        else {
            setSubmit(false);
            formValid = false;

            if (errors) {
                if (typeof errors === 'object') {
                    for (let obj of errors) {
                        if (obj.param === 'phone') {
                            setErrorPhone(1);
                        }
                        if (obj.param === 'email') {
                            setErrorMail(1);
                        }
                        if (obj.param === 'city') {
                            setErrorCity(1);
                        }
                    }
                }
            }
        }
        if ((client === 'firmowy' && errorCompany) || errorMail || errorPhone || errorCity || errorDepartment || errorSubject || errorMessageInput) {
            setSubmitMessageVisible(false);
            setErrorMessage('Wypełnij wymagane pola')
        } else if (!success || errors) {
            setSubmitMessageVisible(false);
            setErrorMessage('Wypełnij wymagane pola  ')
        } else if (!formSuccess && !rodoStatus && !formValid) {
            setErrorMessage('Zaakceptuj zgodę')
        } else if (formError) {
            setErrorMessage('Wystąpił błąd. Przepraszamy. Spróbuj ponownie później.')
        }

        if (formSuccess) {
            setErrorMessageVisible(false);
            setSubmitMessage('Formularz został wysłany. Dziękujemy.')
        } else {
            setErrorMessageVisible(true);
        }
        setPending(false);
    };

    //function invoked on form submit (after clicking 'send message' submit button)
    const submitHandler = async (e) => {

        e.preventDefault();
        let allData = false;

        if (formValid) {
            if (agreeRodo) {
                allData = true;
                dataToSend = {
                    clientType: client
                };
                if (name) {
                    dataToSend = {
                        ...dataToSend,
                        name: name
                    };
                }
                if (client === 'firmowy') {
                    dataToSend = {
                        ...dataToSend,
                        companyName: companyName
                    };
                }
                if (remail.test(email)) {
                    dataToSend = {
                        ...dataToSend,
                        email: email
                    };
                }
                if (rephone.test(valueNumber)) {
                    dataToSend = {
                        ...dataToSend,
                        phone: valueNumber.replace(/\+48/, '')
                    };
                }
                let filesObj = {};

                files.forEach((file, index) => {

                    filesObj['file' + (index + 1)] = file;
                });
                dataToSend = {
                    ...dataToSend,
                    city: city,
                    department: department,
                    message: message,
                    subject: subject,
                    agree: agreeRodo,
                    ...files
                };
                setErrorMessageVisible(false);
                //log(dataToSend);
            }
        }
        if (allData) {
            setPending(true);

            ReactRecaptcha3.getToken().then((token) => {

                dataToSend = {
                    ...dataToSend,
                    'g-recaptcha-response': token
                };
                let url = '/forms-api/contact/send';   //URL for form to send

                if (origin.includes(environment.devName)) {
                    url = environment.siteUrlDev + url;
                }
                if (origin.includes(environment.localhost)) {
                    url = environment.siteUrlTest + '/forms-api/contact/send';    //test URL on localhost
                }

                axios.post(url, dataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                    .then((res) => {

                        const success = res.data.message;
                        const errors = res.data.errors;

                        if (success) {
                            log('success');
                            setSubmitMessageVisible(true);
                            setFormSuccess(true);
                            setFormError(false);

                        }
                    })
                    .catch(error => {
                        setSubmitMessage(
                            "Wystąpił błąd w wysyłce formularza. Spróbuj ponownie."
                        );
                        console.error('Error [catched]:', error);
                        setFormError(true);
                    });
            },
                (error) => {
                    setSubmitMessage(
                        "Wystąpił błąd w wysyłce formularza. Spróbuj ponownie."
                    );
                    log('Error [recaptcha]: ' + error);
                    setFormError(true);
                }
            );
        }
    }

    //function executed automatically after sending the form (useEffect when formError or formSuccess changes)
    function afterSending(success, errors) {

        //clearing inputs and selects form elements and variables
        if (success) {
            selClient.current.selectOption(allowedClientTypes[0]);  //indiviual client
            setClient(allowedClientTypes[0].value);
            inpCompany.current.value = '';
            inpCompany.current.disabled = true;
            inpName.current.value = '';
            inpEmail.current.value = '';
            inpPhone.current.value = '';
            inpCity.current.value = '';
            selDepartment.current.selectOption(allowedDepartments[0]);
            selSubject.current.selectOption(allowedSubjects[0]);
            inpMessage.current.value = '';
            inpRodo.current.checked = false;
            setClear(true); //remove attachments
            setFiles([]);
        }
        StatusHandler(!success, success, errors);
    }

    /* field validations (in real time) */
    //phone validation
    const PhoneErrors = () => {

        let html = [];

        if (errorPhone >= 1) {
            html.push(`Podaj telefon lub email`);
        }
        if (errorPhone === 2) {
            html.push(`Numer telefonu jest nieprawidłowy`);
        }
        return (<span className="msg" dangerouslySetInnerHTML={{ __html: html.join(`, `) }}></span>);
    };

    const MailErrors = () => {

        let html = [];

        if (errorMail >= 1) {
            html.push(`Podaj email lub telefon`);
        }
        if (errorMail === 2) {
            html.push(`Adres email jest nieprawidłowy`);
        }
        return (<span className="msg" dangerouslySetInnerHTML={{ __html: html.join(`, `) }}></span>);
    };

    const CityErrors = () => {

        let html = [];

        if (errorCity === 1) {
            html.push('Podaj miejscowość');
        }
        if (errorCity === 2) {
            html.push(`Nazwa jest nieprawidłowa`);
        }
        if (errorCity === 3) {
            html.push(`Nazwa jest zbyt krótka`);
        }
        return (<span className="msg" dangerouslySetInnerHTML={{ __html: html.join(`, `) }}></span>);
    };

    const FieldErrors = (param) => {

        let html = [];

        html.push(param.text);

        return (<span className="msg" dangerouslySetInnerHTML={{ __html: html.join(`, `) }}></span>);
    };

    return (
        <section className={innerSection} id='form-scroll'>
            <div className="wrapper">
                <div className={innerContainer}>
                    {params.block['en']['Title'] &&
                        <h2>{params.block['en']['Title']}</h2>
                    }
                    {isLoading ? <span className="load">Wczytywanie danych do formularza...</span> :
                        <form className="form-box" id="contactForm" name="sentMessage" action="/ajax/contact/" method="post" onSubmit={submitHandler}>
                            {errorMessageVisible && (
                                <div className={`message errors`}>
                                    <span>{errorMessage ? errorMessage : "Wystąpił błąd"}</span>
                                </div>
                            )}
                            {submitmessageVisible && !errorMessageVisible && (
                                <div className="message">
                                    <span className="mr-3">{submitMessage}</span>
                                </div>
                            )}
                            <div className="grid-area">
                                <fieldset className="select">
                                    <ReactSelect
                                        isSearchable={false}
                                        ref={selClient}
                                        name="client"
                                        id="client"
                                        options={allowedClientTypes}
                                        defaultValue={allowedClientTypes[0]}
                                        onChange={(input) => { changeClientType(input.value); }}
                                    />
                                    <span className="placeholder active">Typ klienta</span>
                                </fieldset>
                                <fieldset>
                                    <input id="name" type="text" name="name"
                                        className="w-100"
                                        ref={inpName}
                                        value={name}
                                        onChange={
                                            e => changeName(e.target.value)
                                        }
                                    />
                                    <span className="placeholder">Imię i nazwisko</span>
                                </fieldset>
                                <fieldset className="hide">
                                    {!formSuccess && errorCompany > 0 && <FieldErrors text="Wpisz nazwę firmy" />}
                                    <input id="companyName" type="text" className="w-100 required" name="companyName" disabled="disabled" maxLength={200}
                                        ref={inpCompany}
                                        value={companyName}
                                        onChange={
                                            e => { changeCompany(e.target.value) }
                                        }
                                    />
                                    <span className="placeholder">Nazwa firmy</span>
                                </fieldset>
                                <fieldset>
                                    {!formSuccess && errorMail > 0 && <MailErrors />}
                                    <input id="email" type="text" name="email" className="w-100 required"
                                        ref={inpEmail}
                                        onChange={
                                            e => { validateEmail(e.target.value) }
                                        }
                                    />
                                    <span className="placeholder">Adres e-mail</span>
                                </fieldset>
                                <fieldset className="phone">
                                    {!formSuccess && errorPhone > 0 && <PhoneErrors />}
                                    <PhoneInput
                                        country="PL"
                                        international
                                        withCountryCallingCode
                                        ref={inpPhone}
                                        value={valueNumber}
                                        onChange={
                                            number => { changePhone(number); }
                                        }
                                        maxLength="16"
                                        className="w-100 required"
                                        id="phone"
                                    />
                                    <span className="placeholder">Numer telefonu</span>
                                </fieldset>
                                <fieldset>
                                    {!formSuccess && errorCity > 0 && <CityErrors />}
                                    <input id="city" type="text" className="w-100 required" name="city"
                                        ref={inpCity}
                                        value={city}
                                        onChange={
                                            e => { changeCity(e.target.value) }
                                        }
                                    />
                                    <span className="placeholder">Miejscowość</span>
                                </fieldset>
                                <fieldset className="select">
                                    {!formSuccess && errorDepartment && <FieldErrors text="Wybierz oddział" name="department" />}
                                    <ReactSelect
                                        isSearchable={false}
                                        ref={selDepartment}
                                        name="department"
                                        id="department"
                                        className="required"
                                        options={allowedDepartments}
                                        defaultValue={allowedDepartments[0]}
                                        onChange={(input) => { changeDepartment(input.value); }}
                                    />
                                    <span className="placeholder">Oddział banku</span>
                                </fieldset>
                            </div>
                            <div className="grid-area">
                                <fieldset className="select">
                                    {!formSuccess && errorSubject && <FieldErrors text="Wybierz temat" name="subject" />}
                                    <ReactSelect
                                        isSearchable={false}
                                        ref={selSubject}
                                        name="subject"
                                        id="subject"
                                        className="required"
                                        options={allowedSubjects}
                                        defaultValue={allowedSubjects[0]}
                                        onChange={(input) => { changeSubject(input.value); }}
                                    />
                                    <span className="placeholder">Temat</span>
                                </fieldset>
                                <fieldset className="textarea">
                                    {!formSuccess && errorMessageInput && <FieldErrors text="Wpisz wiadomość" name="message" />}
                                    <textarea className="w-100 required" id="message" name="message" rows="10" cols="100"
                                        ref={inpMessage}
                                        onChange={
                                            e => { changeMessage(e.target.value) }
                                        }
                                    >
                                    </textarea>
                                    <span className="placeholder">Wiadomość</span>
                                </fieldset>
                                <fieldset>
                                    <FileUploader
                                        id="other"
                                        accepted={allowedMimeTypes}
                                        maxFileSize={maxAllowedSize}
                                        maxFiles={maxFilesNumber}
                                        filesToSend={filesToSend}
                                        removeAllFiles={clear}
                                        uploaded={files}
                                        text="(.pdf/.jpg)"
                                        setErrorMessage={setErrorMessage}
                                        setWrongFile={setWrongFile}
                                    />
                                </fieldset>
                            </div>
                            <div className="grid-area checkbox-container required">
                                <label>
                                    <input className="agree" name="agreeRodo" id="rodo" type="checkbox"
                                        ref={inpRodo}
                                        onChange={(e) => { statusHandlerRodo(e.target.checked) }} />
                                    <span>&nbsp;</span>
                                    <div>
                                        {agreeText && (
                                            <span className="text" dangerouslySetInnerHTML={{ __html: agreeText }}></span>
                                        )}
                                    </div>
                                </label>
                            </div>
                            <div id="desc" className="grid-area">
                                {additionalText && (
                                    <span className="text" dangerouslySetInnerHTML={{ __html: additionalText }}></span>
                                )}
                            </div>
                            <div className="grid-area submit ta-center">
                                <button className="button primary" type="submit"
                                    disabled={pending ? 'disabled' : ''}
                                    onClick={(e) => {handleClickScroll();StatusHandler(true)}}>Wyślij wiadomość</button>
                            </div>
                        </form>}
                </div>
            </div>
        </section>
    );
}

export default ContactForm;