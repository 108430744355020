import React, { useState } from "react";

const LegalNote = ({ setActiveLegalNote, LegalNote, classButton, svg }) => {
  const [isLegalVisible, setIsLegalVisible] = useState(false);

  function legalHandleOpen() {
    setIsLegalVisible(true);
    if (setActiveLegalNote) {
      setActiveLegalNote('active');
    }
  }

  function legalHandleClose() {
    setIsLegalVisible(false);
    if (setActiveLegalNote) {
      setActiveLegalNote('closed');
    }
  }

  return (
    <div className="legal-notes-wrapper">
      {svg && 
        <span
          className={classButton}
          onClick={() => {
            legalHandleOpen();
          }}
        > 
         <span dangerouslySetInnerHTML= {
              { __html: svg }
          }></span>
          Nota prawna
        </span>
      }
      {!svg && 
        <span
          className={classButton}
          onClick={() => {
            legalHandleOpen();
          }}
        > 
          NOTA PRAWNA
        </span>
      }
      <div className={`legal-note ${isLegalVisible ? "open" : ""}`}>
        <span
          className="close"
          onClick={() => {
            legalHandleClose();
          }}
        ></span>
        <div
          className="legal-note-body"
          dangerouslySetInnerHTML={{ __html: LegalNote }}
        ></div>
      </div>
    </div>
  );
};

export default LegalNote;