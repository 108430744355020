import React from "react";
import { environment } from "./../../environment";
import { PieChart, Pie, Sector, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, } from "recharts";
import useWindowDimensions from "../../Device.js";

// data for charts

const dataFinancialResult = [
  {
    name: "2022",
    netto: 8_238,
    amt: 8500,
  },
  {
    name: "2023",
    netto: 28_135,
    amt: 30000,
  },
];

const totalAssets2022 = [
  {
    name: "Aktywa ogółem (tys. zł)",
    value: 1043920,
  },
  {
    name: "Fundusze własne (tys. zł)",
    value: 82193,
  },
];

const totalAssets2023 = [
  {
    name: "Aktywa ogółem (tys. zł)",
    value: 1134317,
  },
  {
    name: "Fundusze własne (tys. zł)",
    value: 89625,
  },
];

const solvencyRatio = [
  {
    name: "2022",
    ratio: 16.88,
  },
  {
    name: "2023",
    ratio: 16.80,
  },
];

const lcrRatio = [
  {
    name: "2022",
    lcr: 236.48,
  },
  {
    name: "2023",
    lcr: 174.51,
  },
];

const roa = [
  {
    name: "2022",
    roa: 0.76,
  },
  {
    name: "2023",
    roa: 2.70,
  }
];

const roe = [
  {
    name: "2022",
    roe: 10.33,
  },
  {
    name: "2023",
    roe: 32.81,
  }
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      fontSize="14px"
      fontFamily="Barlow Light, Arial, sans-serif"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {/* {`${(percent * 100).toFixed(0)}%`} */}
      {totalAssets2022[index].value.toLocaleString('pl')}
    </text>
  );
};

const renderCustomizedLabel2 = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      fontSize="14px"
      fontFamily="Barlow Light, Arial, sans-serif"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {/* {`${(percent * 100).toFixed(0)}%`} */}
      {totalAssets2023[index].value.toLocaleString('pl')}
    </text>
  );
};

//Label for bars
const CustomizedLabel = ({ x, y, fill, value }) => {
  return (
    // 85 (half of bar width)
    <text
      x={x + 85}
      y={y}
      dy={-4}
      fontSize="16"
      fill={fill}
      textAnchor="middle"
    >
      {value}%
    </text>
  );
};

const CustomizedLabelCurrency = ({ x, y, fill, value, w, fillText }) => {
  return (
    // 85 (half of bar width)
    <text
      x={x + (w / 2)}
      y={y}
      dy={-4}
      fontSize="16"
      fontFamily="Barlow Light, Arial, sans-serif"
      fill={fillText}
      textAnchor="middle"
    >
      {value}
    </text>
  );
};

const CustomizedLabelPercent = ({ x, y, fill, value, w, fillText }) => {
  return (
    // 85 (half of bar width)
    <text
      x={x + (w / 2)}
      y={y}
      dy={-4}
      fontSize="16"
      fontFamily="Barlow Light, Arial, sans-serif"
      fill={fillText}
      textAnchor="middle"
    >
      {value}%
    </text>
  );
};

const Charts = (params) => {
  const { width } = useWindowDimensions();

  if (
    window.origin.includes(environment.localhost) ||
    window.origin.includes(environment.devName)
  ) {
    console.log("Charts");
    console.log(params);
  }

  let colorBack = "";
  if (
    params.block["en"]["Background"] !== "" &&
    params.block["en"].hasOwnProperty("Background")
  ) {
    colorBack = "b-" + params.block["en"]["Background"];
  }
  let innerSection = "charts";
  let innerContainer = "container d-flex f-column jc-center";
  if (
    params.block["en"]["InnerBackground"] !== "" &&
    params.block["en"].hasOwnProperty("InnerBackground")
  ) {
    if (params.block["en"]["InnerBackground"] === true) { 
      innerContainer = innerContainer + " " + colorBack;
    } else {
      innerSection = innerSection + " " + colorBack;
    }
  }

  //labels for pie
  const greenDark = "#2DA343"
  const greyDark = "#8C8D91"

  const pieColorsGrey = ['#8C8D91', '#707173'] 
  const pieColorsGreen = ['#2DA343', '#268938'] 

  const barColors = [greyDark, greenDark]

  if(width > 1050) {
    return (
      <section className={innerSection}>
        <div className="wrapper">
          <div className={innerContainer}>
            {params.block["en"]["Title"] && (
              <h2 className="mb-20">{params.block["en"]["Title"]}</h2>
            )}
          
            <div className="chart-wrapper">
              <h3 className="mb-40 ta-center">Wynik finansowy netto (tys. zł)</h3>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={dataFinancialResult.slice()}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid vertical={false} stroke="#ebf3f0" />
                  <XAxis dataKey="name" tick={{fontSize: 16, fontFamily: "Barlow Light, Arial, sans-serif"}} />
                  <YAxis tick={{fontSize: 16, fontFamily: "Barlow Light, Arial, sans-serif"}} />
                  <Tooltip position={{ x: 'auto', y: 200 }} />
                  <Bar dataKey="netto" fill="#90D14F" barSize={170} label={<CustomizedLabelCurrency w={170} />}>
                    {dataFinancialResult.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={barColors[index % 20]} fillText="#000" />
                    ))}
                  </Bar>
                  {/* <Bar dataKey="netto2021" fill="#90D14F" barSize={170} label={<CustomizedLabelCurrency w={170} />} /> */}
                </BarChart>
              </ResponsiveContainer>
            </div>

            <h3 className="mt-40 mb-40 ta-center">Aktywa ogółem (tys. zł)</h3>
            <div className="chart-wrapper-2-cols">
              <div className="charts-2-cols">
                <div>
                <h3 className="ta-center mb-40">2022</h3>
                  <ResponsiveContainer width="100%" height="100%">
                  <PieChart width={400} height={400}>
                    <Pie
                      data={totalAssets2022}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={200}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {totalAssets2022.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={pieColorsGrey[index % pieColorsGrey.length]}
                        />
                      ))}
                    </Pie>
                    <Legend formatter={(value, entry, index) => <span className="text-color-class">{value}</span>} iconType="circle" />
                  </PieChart>
                </ResponsiveContainer>
                </div>
                <div>
                <h3 className="ta-center mb-40">2023</h3>
                  <ResponsiveContainer width="100%" height="100%">
                  <PieChart width={400} height={400}>
                    <Pie
                      data={totalAssets2023}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel2}
                      outerRadius={200}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {totalAssets2023.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={pieColorsGreen[index % pieColorsGreen.length]}
                        />
                      ))}
                    </Pie>
                    <Legend formatter={(value, entry, index) => <span className="text-color-class">{value}</span>} iconType="circle" />
                  </PieChart>
                </ResponsiveContainer>
                </div>
              </div>
            </div>
  
  
            <div className="chart-wrapper-2-cols">
              <div className="charts-2-cols">
                <div>
                <h3 className="ta-center mb-40">Współczynnik wypłacalności</h3>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={solvencyRatio}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid vertical={false} stroke="#ebf3f0" />
                    <XAxis dataKey="name" tick={{fontSize: 16, fontFamily: "Barlow Light, Arial, sans-serif"}} />
                    <YAxis tick={{fontSize: 16, fontFamily: "Barlow Light, Arial, sans-serif"}} />
                    <Tooltip position={{ x: 'auto', y: 200 }} />
                    <Bar dataKey="ratio" fill={greyDark} barSize={110}  label={<CustomizedLabelPercent w={110}/>}>
                    {solvencyRatio.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={barColors[index % 20]} fillText="#000" />
                    ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
                </div>
                <div>
                <h3 className="ta-center mb-40">Wskaźnik płynności LCR</h3>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={lcrRatio}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid vertical={false} stroke="#ebf3f0" />
                    <XAxis dataKey="name" tick={{fontSize: 16, fontFamily: "Barlow Light, Arial, sans-serif"}} />
                    <YAxis tick={{fontSize: 16, fontFamily: "Barlow Light, Arial, sans-serif"}} />
                    <Tooltip position={{ x: 'auto', y: 200 }} />
                    <Bar dataKey="lcr" fill={greenDark} barSize={110} label={<CustomizedLabelPercent w={110} />}>
                    {lcrRatio.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={barColors[index % 20]} fillText="#000" />
                    ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
                </div>
              </div>
            </div>
  
            <h3 className="mt-40 mb-40 ta-center">Wskaźniki rentowności</h3>
            <div className="chart-wrapper-2-cols">
              <div className="charts-2-cols">
                <div>
                <h3 className="ta-center mb-40">ROA</h3>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={roa}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid vertical={false} stroke="#ebf3f0" />
                    <XAxis dataKey="name" tick={{fontSize: 16, fontFamily: "Barlow Light, Arial, sans-serif"}} />
                    <YAxis tick={{fontSize: 16, fontFamily: "Barlow Light, Arial, sans-serif"}} />
                    <Tooltip position={{ x: 'auto', y: 200 }} />
                    <Bar dataKey="roa" fill={greyDark} barSize={110}  label={<CustomizedLabelPercent w={110}/>} />
                  </BarChart>
                </ResponsiveContainer>
                </div>
                <div>
                <h3 className="ta-center mb-40">ROE</h3>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={roe}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid vertical={false} stroke="#ebf3f0" />
                    <XAxis dataKey="name" tick={{fontSize: 16, fontFamily: "Barlow Light, Arial, sans-serif"}} />
                    <YAxis tick={{fontSize: 16, fontFamily: "Barlow Light, Arial, sans-serif"}} />
                    <Tooltip position={{ x: 'auto', y: 200 }} />
                    <Bar dataKey="roe" fill={greenDark} barSize={110} label={<CustomizedLabelPercent w={110} />} />
                  </BarChart>
                </ResponsiveContainer>
                </div>
              </div>
            </div>
  
  
            
  
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className={innerSection}>
        <div className="wrapper">
          <div className={innerContainer}>
            {params.block["en"]["Title"] && (
              <h2 className="mb-20">{params.block["en"]["Title"]}</h2>
            )}
          
            <div className="chart-wrapper">
              <h3 className="mb-40 ta-center">Wynik finansowy netto (tys. zł)</h3>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={dataFinancialResult.slice()}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid vertical={false} stroke="#ebf3f0" />
                  <XAxis dataKey="name" tick={{fontSize: 16, fontFamily: "Barlow Light, Arial, sans-serif"}} />
                  <YAxis tick={{fontSize: 16, fontFamily: "Barlow Light, Arial, sans-serif"}} />
                  <Tooltip position={{ x: 'auto', y: 200 }} />
                  <Bar dataKey="netto" fill="#90D14F" barSize={170} label={<CustomizedLabelCurrency w={170} />}>
                    {dataFinancialResult.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={barColors[index % 20]} fillText="#000" />
                    ))}
                  </Bar>
                  {/* <Bar dataKey="netto2021" fill="#90D14F" barSize={170} label={<CustomizedLabelCurrency w={170} />} /> */}
                </BarChart>
              </ResponsiveContainer>
            </div>

            <h3 className="ta-center mt-40">Aktywa ogółem (tys. zł)</h3>
                <div className="chart-wrapper">
                  <h3 className="ta-center mb-40">2022</h3>
                    <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={400} height={400}>
                      <Pie
                        data={totalAssets2022}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={200}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {totalAssets2022.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={pieColorsGrey[index % pieColorsGrey.length]}
                          />
                        ))}
                      </Pie>
                      <Legend formatter={(value, entry, index) => <span className="text-color-class">{value}</span>} iconType="circle" />
                    </PieChart>
                  </ResponsiveContainer>
                  </div>
                  <h3 className="ta-center mt-40">Aktywa ogółem (tys. zł)</h3>
                <div className="chart-wrapper">
                  <h3 className="ta-center mb-40">2023</h3>
                    <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={400} height={400}>
                      <Pie
                        data={totalAssets2023}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel2}
                        outerRadius={200}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {totalAssets2023.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={pieColorsGreen[index % pieColorsGreen.length]}
                          />
                        ))}
                      </Pie>
                      <Legend formatter={(value, entry, index) => <span className="text-color-class">{value}</span>} iconType="circle" />
                    </PieChart>
                  </ResponsiveContainer>
                </div>


            <div className="chart-wrapper">
              <h3 className="ta-center mb-40">Współczynnik wypłacalności</h3>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      width={500}
                      height={300}
                      data={solvencyRatio}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid vertical={false} stroke="#ebf3f0" />
                      <XAxis dataKey="name" tick={{fontSize: 16, fontFamily: "Barlow Light, Arial, sans-serif"}} />
                      <YAxis tick={{fontSize: 16, fontFamily: "Barlow Light, Arial, sans-serif"}} />
                      <Tooltip position={{ x: 'auto', y: 200 }} />
                      <Bar dataKey="ratio" fill={greyDark} barSize={110}  label={<CustomizedLabelPercent w={110}/>}>
                      {solvencyRatio.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={barColors[index % 20]} fillText="#000" />
                      ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>



                <div className="chart-wrapper">
                <h3 className="ta-center mb-40">Wskaźnik płynności LCR</h3>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={lcrRatio}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid vertical={false} stroke="#ebf3f0" />
                    <XAxis dataKey="name" tick={{fontSize: 16, fontFamily: "Barlow Light, Arial, sans-serif"}} />
                    <YAxis tick={{fontSize: 16, fontFamily: "Barlow Light, Arial, sans-serif"}} />
                    <Tooltip position={{ x: 'auto', y: 200 }} />
                    <Bar dataKey="lcr" fill={greenDark} barSize={110} label={<CustomizedLabelPercent w={110} />}>
                    {lcrRatio.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={barColors[index % 20]} fillText="#000" />
                    ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
                </div>

                <h3 className="mt-20 ta-center">Wskaźniki rentowności</h3>
                <div className="chart-wrapper">
                  <h3 className="ta-center mb-40">ROA</h3>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      width={500}
                      height={300}
                      data={roa}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid vertical={false} stroke="#ebf3f0" />
                      <XAxis dataKey="name" tick={{fontSize: 16, fontFamily: "Barlow Light, Arial, sans-serif"}} />
                      <YAxis tick={{fontSize: 16, fontFamily: "Barlow Light, Arial, sans-serif"}} />
                      <Tooltip position={{ x: 'auto', y: 200 }} />
                      <Bar dataKey="roa" fill={greyDark} barSize={110}  label={<CustomizedLabelPercent w={110}/>} />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <h3 className="mt-20 ta-center">Wskaźniki rentowności</h3>
                <div className="chart-wrapper">
                  <h3 className="ta-center mb-40">ROE</h3>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      width={500}
                      height={300}
                      data={roe}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid vertical={false} stroke="#ebf3f0" />
                      <XAxis dataKey="name" tick={{fontSize: 16, fontFamily: "Barlow Light, Arial, sans-serif"}} />
                      <YAxis tick={{fontSize: 16, fontFamily: "Barlow Light, Arial, sans-serif"}} />
                      <Tooltip position={{ x: 'auto', y: 200 }} />
                      <Bar dataKey="roe" fill={greenDark} barSize={110} label={<CustomizedLabelPercent w={110} />} />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                
  
          </div>
        </div>
      </section>
    );
  }

  
};

export default Charts;
