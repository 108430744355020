import React from "react";
import { environment } from "./environment";

function RemoveLoader() {
    if (window.origin.includes(environment.localhost) || window.origin.includes(environment.devName)) {
        console.log('Reomve Loader')
    }	
    setTimeout(function(){ 
        var loader = document.getElementById('loader');
        if (loader) {
            loader.remove();
        }
    }, 1);
};
export default RemoveLoader;