import React, { useState } from "react";
import TextLinks from "../../components/TextLinks";

function Faq(params) {
    if (window.origin.includes('localhost')) {
        console.log('Faq')
        console.log(params)
    }

    let colorBack = ''
    if (params.block['en']['FontColor'] !== '' && params.block['en'].hasOwnProperty('FontColor')) {
        colorBack = 'b-' + params.block['en']['FontColor']
    }
    let innerSection = 'accordion container';
    let innerContainer = 'container-wide';
    if (params.block['en']['InnerBackground'] !== '') {
        innerSection = innerSection + ' ' + colorBack;
    }

    const Accordion = ({ title, children }) => {
        const [isOpen, setOpen] = useState(false);
        return (
          <div className="accordion-wrapper mb-20">
            <div
              className={`accordion-title d-flex jc-between ai-center ${isOpen ? "open" : ""}`}
              onClick={() => setOpen(!isOpen)}
            >
              <p>{title}</p><i className="arrow down"></i>
            </div>
            <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
                <div className="accordion-content text">
                    <TextLinks text={children} line={params.line} />
                </div>
            </div>
          </div>
        );
    };
    
    const accordionValues = [];
    accordionValues.push(params.block['en']['Item']);
    
    return (
        <section className={innerSection}>
            <div className="wrapper">
                <div className={innerContainer}>
                    <h2>{params.block['en']['Title']}</h2>
                    {accordionValues.map((item, i) => {
                        return item.map((item, i) => {
                            return (
                                <Accordion title={item.AccordionQuestion} key={'accordion-item-' + i}>
                                    {item.AccordionAnswer}
                                </Accordion>
                            )
                        })
                    })}
                </div>
            </div>
        </section>
    );
}

export default Faq;