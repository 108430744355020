import React from "react";
import Links from "../../components/Links";

function PromtList(params) {
    const arr = params.data.filter((result, i) => i < 20);
    return (
        arr.map((item, i) => (
            <li key={'search-tip-' + i}>
                {params.title && 
                    <Links line={params.line} url={item.url} name={<h5>{params.title}</h5>} target={"true"} />
                }
                <Links line={params.line} url={item.url} name={item.description} target={"true"} />
            </li>
        ))
    )
}

export default PromtList